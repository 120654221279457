import {
  getTeamList, createTeams, getTeamProjectList, transferTeam, getTeamInciteCode, getTeamInviteConfirm, getMemberOfTeam,
  quitTeam, getMemberOfTeamProject, getUserRoleTeamProject, getAllCooperater, addCooperater, delMember, updateMemberPower,
  getUserRole, batchCopyProject, batchCopyProjectToPerson, getShareTeamProjectCode, updateTeamName, getProjectPermissions,
  deleteProject, batchTeamRole, batchProjectPermission, batchDeleteMember, batchDevicePower, updateMemberEdit, uploadDevice, getTeamDevice,
  dissolutionTeam} from "@/api/api"

import { Message } from 'element-ui';

export function getTeamListApi(params) { // 获取团队列表
  getTeamList(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function createTeamApi(params) { // 创建团队
  createTeams(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getTeamProjectListApi(params) { // 获取团队-项目列表
  getTeamProjectList(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function transferTeamApi(params) { // 转让团队
  transferTeam(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getTeamInciteCodeApi(params) { // 获取邀请码
  getTeamInciteCode(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getTeamInviteConfirmApi(params) {// 加入团队
  getTeamInviteConfirm(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getMemberOfTeamApi(params) { // 获取团队-成员列表
  getMemberOfTeam(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function quitTeamApi(params) {// 退出团队
  quitTeam(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getMemberOfTeamProjectApi(params) {// 获取团队-项目-成员列表
  getMemberOfTeamProject(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getUserRoleTeamProjectApi(params) {// 获取团队-项目-成员权限列表
  getUserRoleTeamProject(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getAllCooperaterApi(params) {// 获取所有待成为协作者的列表
  getAllCooperater(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function addCooperaterApi(params) { // 添加协作者
  addCooperater(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function delMemberApi(params) {// 删除成员
  delMember(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function updateMemberPowerApi(params) {// 更新成员项目权限
  updateMemberPower(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getUserRoleApi(params) {// 获取项目下成员的权限
  getUserRole(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchCopyProjectApi(params) {// 批量复制
  batchCopyProject(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchCopyProjectToPersonApi(params) {// 批量复制到个人
  batchCopyProjectToPerson(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getShareTeamProjectCodeApi(params) {// 获取分享码
  getShareTeamProjectCode(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function updateTeamNameApi(params) {// 更新团队信息
  updateTeamName(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function getProjectPermissionsApi(params) {// 获取当前登录用户对项目的权限列表
  getProjectPermissions(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function deleteProjectApi(params) {// 删除团队-项目
  deleteProject(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchTeamRoleApi(params) {// 批量更改团队角色
  batchTeamRole(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchProjectPermissionApi(params) {// 批量更改项目权限
  batchProjectPermission(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchDeleteMemberApi(params) {// 批量删除成员
  batchDeleteMember(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function batchDevicePowerApi(params) {// 批量更改器件库权限
  batchDevicePower(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function updateMemberEditApi(params) {// 成员列表-编辑更新
  updateMemberEdit(params).then(res => {
    if (res.code / 1000 === 200) {
      return res.data;
    } else {
      Message.error(res.message);
    }
  })
}

export function uploadDeviceApi(params) {// 上传器件
  return new Promise((resolve, reject) => {
    uploadDevice(params).then(res => {
      if (res.code / 1000 === 200) {
        resolve(res.data)
      } else {
        Message.error(res.message);
      }
    }).catch (reject); {
    }
  })
}

export function getTeamDeviceApi(params, status) {// 获取团队-器件库列表
  return new Promise((resolve, reject) => {
    getTeamDevice(params).then(res => {
      if (res.code / 1000 === 200) {
        resolve(res.data)
      } else {
        Message.error(res.message);
        resolve(res.data);
      }
    }).catch (reject);{
      return;
    }
  })
}

export function dissolutionTeamApi(params) {// 解散团队
  return new Promise((resolve, reject) => {
    dissolutionTeam(params).then(res => {
      if (res.code / 1000 === 200) {
        resolve(res.data)
      } else {
        Message.error(res.message);
      }
    }).catch (reject); {
    }
  })
}