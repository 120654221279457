<template>
  <div class="info" onselectstart="javascript:return false;">
    <div class="t-item1">
      <div style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)">
        <div class="t-item1__title">{{ $t('messages.myTeam') }}</div>
        <div class="t-item1__body">
          <button class="main-btn default" @click="createTeamVisible = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/创建团队.svg" />
            {{ $t('messages.createTeam') }}
          </button>
          <button class="main-btn default" @click="joinTeamVisible = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/加入团队.svg" />
            {{ $t('messages.joinTeam') }}
          </button>
          <input class="main-input height10" @keyup.enter="serarchTeam()" v-model.trim="searchTeamName"
            :placeholder="$t('messages.placeholder_2')" />
          <div style="width: 100%; max-height: 80%; overflow-y: scroll" v-loading="teamLoading">
            <div v-for="(item, index) in teamData" :key="index" class="teamInfo" :class="{ active: item.selected }"
              @click="teamChange(index)">
              <img alt="" class="team-icon" src="../../assets/icons/personnel/团队名.svg" />
              <div class="team-name">{{ item.team_name }}</div>
              <img alt="" class="role-icon" v-if="item.role == '所有者'" src="../../assets/personalCenter/所有者.svg" />
              <img alt="" class="role-icon" v-if="item.role == '管理者'" src="../../assets/personalCenter/管理员.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t-item2" v-if="teamData.length > 0">
      <div class="t-item2__header">
        <div class="t-item2__title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{ $t('messages.teamDetail') }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $t('messages.myTeam') }}</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="returnTeam"
              :class="{ highlight: !checkMember && !checkPower && !checkFile && !checkCell }">
              {{ teamData[currentTeam].team_name }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="checkMember || checkPower"
              :class="{ highlight: checkMember || checkPower }">{{ checkProject }}{{ $t('messages.sMember') }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="checkFile"
              :class="{ highlight: checkFile }">{{ checkProject }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="checkCell" :class="{ highlight: checkCell }">
              <span @click="returnFile" class="highlight checkFile">{{ checkProjectFileName }}</span>
              <span style="margin: 0 6px; font-weight: 400; color: #c0c4cc" class="el-icon-arrow-right"></span>
              {{ checkProject }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="t-item2__header__info" v-if="checkMember || checkPower">
          <div style="font-size: 22px; font-weight: bold; color: #333333">{{ checkProject }}{{ $t('messages.sMember') }}
          </div>
        </div>
        <div class="t-item2__header__info" v-if="checkFile || checkCell">
          <div style="font-size: 22px; font-weight: bold; color: #333333">{{ checkProject }}</div>
        </div>
        <div class="t-item2__header__info" v-else>
          <img alt="" class="info-icon" src="../../assets/images/personnel/团队默认头像.png" />
          <div class="info-info">
            <strong>{{ teamData[currentTeam].team_name }}</strong>
            <div class="create-info">{{ $t('messages.createTime') }}：{{ teamData[currentTeam].create_time }}</div>
            <button class="info-btn default" v-show="teamData[currentTeam].role == '所有者'"
              @click="updateInfoVisible = true">{{ $t('messages.updateInfo') }}</button>
          </div>
          <div class="team-btn-group">
            <button class="info-btn default margin10" @click="addMemberClick"
              v-show="teamData[currentTeam].role == '管理者' || teamData[currentTeam].role == '所有者'">{{ $t('messages.addPerson') }}</button>
            <button class="info-btn default margin10" @click="toCessionTeam"
              v-show="teamData[currentTeam].role == '所有者'">{{ $t('messages.cessionTeam') }}</button>
            <button class="info-btn default margin10" @click="toDismissTeam"
              v-show="teamData[currentTeam].role == '所有者'">{{ $t('messages.dismissTeam') }}</button>
            <button class="info-btn default margin10" @click="quitTeamVisible = true"
              v-show="teamData[currentTeam].role == '协作者' || teamData[currentTeam].role == '管理者'">{{ $t('messages.quitTeam') }}</button>
          </div>
        </div>
      </div>
      <div class="t-item2__body" v-if="checkMember">
        <div class="t-item" v-if="checkMember && !checkPower && !memberTableDatabatchDelete">
          <button @click="changeCooperaterData" v-if="currentUserRoleId !== 3" class="main-btn default">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/添加成员.svg" />
            {{ $t('messages.inviteMember') }}
          </button>
          <button class="main-btn default" v-if="currentUserRoleId !== 3" @click="toBatchPower">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/权限.svg" />
            {{ $t('messages.batchPower') }}
          </button>
          <button class="main-btn danger" v-if="currentUserRoleId !== 3" @click="toMemberTableDatabatchDelete">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <input class="main-input" v-model.trim="searchMemberOfTeamProject"
            :placeholder="$t('messages.placeholder_4')" />
          <button class="main-btn fill" @click="searchMemberOfTeamProjectClick">{{ $t('messages.search') }}</button>
        </div>
        <div class="t-item" v-if="checkPower">
          <button class="main-btn default"
            @click="updateMemberOfProjectPower(1)">{{ $t('messages.editAndDelete') }}</button>
          <!-- <button class="main-btn default" @click="updateMemberOfProjectPower(2)">编辑</button> -->
          <button class="main-btn default" @click="updateMemberOfProjectPower(3)">{{ $t('messages.readOnly') }}</button>
          <button class="main-btn danger" @click="cancelUpdateMemberOfProjectPower">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="t-item" v-if="memberTableDatabatchDelete">
          <button @click="clickMemberTableDatabatchDelete" class="main-btn default">{{ $t('messages.delete') }}</button>
          <button @click="cancelMemberTableDatabatchDelete" class="main-btn danger">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="t-table">
          <el-table ref="memberTable" :data="memberTableData" width="100%" height="70vh"
            header-cell-class-name="table_header" v-if="projectFocus" v-loading="memberTableLoading">
            <el-table-column type="selection" align="center" v-if="checkPower || memberTableDatabatchDelete" width="100"
              :selectable="memberTableSelectable"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (memberCurrentPage - 1) * memberPageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.member')" prop="display_name" align="center" width="400">
              <template #default="scope">
                <div class="table-column__membername" :title="scope.row.display_name">{{ scope.row.display_name }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.teamRole')" prop="user_role" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.user_role == '所有者'" type="owner">{{ scope.row.user_role }}</el-tag>
                <el-tag v-if="scope.row.user_role == '管理者'" type="manager">{{ scope.row.user_role }}</el-tag>
                <div v-if="scope.row.user_role == '协作者'">{{ scope.row.user_role }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.projectPermission')" prop="permission"
              align="center"></el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <i style="color: #0c7de6"
                  :class="{ disabled: !scope.row.is_select || currentUserRoleId == scope.row.user_role_id, 'el-icon-delete': true }"
                  @click="deleteSingleMemberOfproject(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="memberSizeChange"
            @current-change="memberCurrentChange" :current-page="memberCurrentPage" :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="memberPageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="memberTotal"></el-pagination>
        </div>
      </div>
      <div class="t-item2__body" v-if="checkFile">
        <div class="t-item">
          <div v-if="!selectShare && !selectCopy && !selectDelete">
            <button class="main-btn default" @click="selectCopy = true">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/复制到.svg" />
              {{ $t('messages.batchCopyTo') }}
            </button>
            <button class="main-btn default" @click="selectShare = true">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/分享.svg" />
              {{ $t('messages.batchShare') }}
            </button>
            <button class="main-btn danger" @click="selectDelete = true">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" @keyup.enter="searchFileName" v-model="fileSearchName"
              :placeholder="$t('messages.pleaseInputName')" />
            <button class="main-btn fill" @click="searchFileName">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectShare">
            <button class="main-btn default" @click="toFileShare">{{ $t('messages.toShare') }}</button>
            <button class="main-btn default"
              @click=";(selectShare = false), resetSelection('fileTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectCopy">
            <button class="main-btn default" @click="toFileCopy">{{ $t('messages.copy') }}</button>
            <button class="main-btn default"
              @click=";(selectCopy = false), resetSelection('fileTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectDelete">
            <button class="main-btn default" @click="toFileDelete">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDelete = false), resetSelection('fileTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-table">
          <el-table ref="fileTable" v-loading="loading" :data="fileTableData" width="100%" height="70vh"
            header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete"
              width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (fileCurrentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.fileName')" prop="name" align="center">
              <template slot-scope="scope">
                <div @click="checkCells(scope.row)" style="cursor: pointer; color: #0c7de6">
                  <span style="position: relative; top: 4px" class="iconfont icon-yuanlituwenjianmingcheng"
                    v-if="scope.row.types == 3"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-qijianming"
                    v-else-if="scope.row.types == 2"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-dakaifuhao"
                    v-else-if="scope.row.types == 4"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-dakaifuhao"
                    v-else-if="scope.row.types == 5"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-bantuwenjian" v-else></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center"
              width="300"></el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="fileSizeChange" @current-change="fileCurrentChange"
            :current-page="fileCurrentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="filePageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="fileTotal"></el-pagination>
        </div>
      </div>
      <div class="t-item2__body" v-if="checkCell">
        <div class="t-item">
          <div v-if="!selectShare && !selectCopy && !selectDelete">
            <button class="main-btn danger" @click="selectDelete = true">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" @keyup.enter="searchCellName" v-model="cellSearchName"
              :placeholder="$t('messages.pleaseInputName')" />
            <button class="main-btn fill" @click="searchCellName">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectDelete">
            <button class="main-btn default" @click="toCellDelete">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDelete = false), resetSelection('cellTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-table">
          <el-table ref="cellTable" v-loading="loading" :data="cellTableData" width="100%" height="70vh"
            header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete"
              width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (cellCurrentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.fileName')" prop="name" align="center">
              <template slot-scope="scope">
                <div style="cursor: pointer; color: #0c7de6">
                  <span style="position: relative; top: 4px" class="iconfont icon-yuanlituwenjianmingcheng"
                    v-if="scope.row.types == 3"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-qijianming"
                    v-else-if="scope.row.types == 2"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-dakaifuhao"
                    v-else-if="scope.row.types == 4"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-dakaifuhao"
                    v-else-if="scope.row.types == 5"></span>
                  <span style="position: relative; top: 4px" class="iconfont icon-bantuwenjian" v-else></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center"
              width="300"></el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="cellSizeChange" @current-change="cellCurrentChange"
            :current-page="cellCurrentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="cellPageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="cellTotal"></el-pagination>
        </div>
      </div>
      <div class="t-item2__body" v-else>
        <div class="t-tabs">
          <div :class="{ active: projectFocus, 't-tabs__item': true }" @click="vChange(0)">{{ $t('messages.project') }}
          </div>
          <div :class="{ active: clFocus, 't-tabs__item': true }" @click="vChange(1)">
            {{ $t('messages.ComponentLibrary') }}</div>
          <div :class="{ active: symbolFocus, 't-tabs__item': true }" @click="vChange(2)">
            {{ $t('messages.SymbolLibrary') }}</div>
          <div :class="{ active: memberFocus, 't-tabs__item': true }" @click="vChange(3)">{{ $t('messages.member') }}
          </div>
        </div>
        <div class="t-item" v-if="projectFocus">
          <div v-if="!selectShare && !selectCopy && !selectDelete">
            <button @click="selectCopy = true" class="main-btn default">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/复制到.svg" />
              {{ $t('messages.batchCopyTo') }}
            </button>
            <button @click="selectShare = true" class="main-btn default">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/分享.svg" />
              {{ $t('messages.batchShare') }}
            </button>
            <button @click="selectDelete = true" class="main-btn danger">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" v-model.trim="searchProjectName" :placeholder="$t('messages.placeholder_3')" />
            <button class="main-btn fill" @click="searchForProjectName">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectShare">
            <button class="main-btn default" @click="toShare">{{ $t('messages.toShare') }}</button>
            <button class="main-btn default"
              @click=";(selectShare = false), resetSelection('projectTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectCopy">
            <button class="main-btn default" @click="toCopy">{{ $t('messages.copy') }}</button>
            <button class="main-btn default"
              @click=";(selectCopy = false), resetSelection('projectTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectDelete">
            <button class="main-btn default" @click="toDelete">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDelete = false), resetSelection('projectTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-item" v-if="clFocus">
          <div v-if="!selectDeviceDelete && !selectDeviceExport">
            <button @click="batchAddComponentVisible = true" v-show="component_permission == 3" class="main-btn fill">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/Pell_添加.svg" />
              {{ $t('messages.batchAddComponent') }}
            </button>
            <button @click="selectDeviceExport = true" v-show="component_permission == 3" class="main-btn default">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/导出.svg" />
              {{ $t('messages.batchExportGDS') }}
            </button>
            <button @click="selectDeviceDelete = true" v-show="component_permission == 3" class="main-btn danger">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" v-model.trim="searchDevice" :placeholder="$t('messages.searchForKeyword')" />
            <button class="main-btn fill" @click="searchDeviceClick">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectDeviceExport">
            <button class="main-btn default" @click="toExportDevice">{{ $t('messages.batchExportGDS') }}</button>
            <button class="main-btn default"
              @click=";(selectDeviceExport = false), resetSelection('clTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectDeviceDelete">
            <button class="main-btn default" @click="toDeleteDevice">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDeviceDelete = false), resetSelection('clTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-item" v-if="symbolFocus">
          <div v-if="!selectDeviceDelete && !selectDeviceExport">
            <button @click="batchAddSymbolVisible = true" v-show="component_permission == 3" class="main-btn fill">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/Pell_添加.svg" />
              {{ $t('messages.batchAddSymbol') }}
            </button>
            <button @click="selectDeviceDelete = true" v-show="component_permission == 3" class="main-btn danger">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" v-model.trim="searchSymbol" :placeholder="$t('messages.searchForKeyword')" />
            <button class="main-btn fill" @click="searchSymbolClick">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectDeviceDelete">
            <button class="main-btn default" @click="toDeleteStmbol">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDeviceDelete = false), resetSelection('symbolTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-item" v-if="memberFocus">
          <div v-if="!selectTeamPower && !selectDevicePower && !selectDeleteMember">
            <button @click="selectTeamPower = true" v-show="currentUserRoleType == '所有者'" class="main-btn default">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/权限.svg" />
              {{ $t('messages.batchTeamRoleManage') }}
            </button>
            <button @click="toUpdateProjectPower" v-show="currentUserRoleType == '管理者' || currentUserRoleType == '所有者'"
              class="main-btn fill">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/权限2.svg" />
              {{ $t('messages.batchProjectManage') }}
            </button>
            <button @click="selectDevicePower = true"
              v-show="currentUserRoleType == '管理者' || currentUserRoleType == '所有者'" class="main-btn default">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/权限.svg" />
              {{ $t('messages.batchDeviceManage') }}
            </button>
            <button @click="selectDeleteMember = true"
              v-show="currentUserRoleType == '管理者' || currentUserRoleType == '所有者'" class="main-btn danger">
              <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
              {{ $t('messages.batchDelete') }}
            </button>
            <input class="main-input" v-model.trim="searchMemberOfProject"
              :placeholder="$t('messages.placeholder_4')" />
            <button class="main-btn fill" @click="searchMemberOfProjectBtn">{{ $t('messages.search') }}</button>
          </div>
          <div v-if="selectTeamPower">
            <button class="main-btn default" @click="setTeamPower('协作者')">{{ $t('messages.collaborator') }}</button>
            <button class="main-btn default" @click="setTeamPower('管理者')">{{ $t('messages.manager') }}</button>
            <button class="main-btn default"
              @click=";(selectTeamPower = false), resetSelection('projectMemberTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectDevicePower">
            <button class="main-btn default" @click="SetDevicePower('只读')">{{ $t('messages.readOnly') }}</button>
            <button class="main-btn default" @click="SetDevicePower('可使用')">{{ $t('messages.canUsed') }}</button>
            <button class="main-btn default" @click="SetDevicePower('可编辑')">{{ $t('messages.canEdit') }}</button>
            <button class="main-btn default"
              @click=";(selectDevicePower = false), resetSelection('projectMemberTable')">{{ $t('messages.cancel') }}</button>
          </div>
          <div v-if="selectDeleteMember">
            <button class="main-btn default" @click="toDeleteMember">{{ $t('messages.delete') }}</button>
            <button class="main-btn default"
              @click=";(selectDeleteMember = false), resetSelection('projectMemberTable')">{{ $t('messages.cancel') }}</button>
          </div>
        </div>
        <div class="t-table">
          <el-table ref="projectTable" :data="projectTableData" width="100%" height="58.5vh"
            header-cell-class-name="table_header" v-if="projectFocus" v-loading="projectTableLoading">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete"
              :selectable="projectTableSelectable" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.projectName')" prop="name" align="center">
              <template slot-scope="scope">
                <div @click="checkFiles(scope.row)" style="cursor: pointer; color: #0c7de6">
                  <span style="position: relative; top: 4px" class="iconfont icon-xiangmuming"></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.member')" prop="project_number" align="center">
              <template slot-scope="scope">
                <span @click="checkMembers(scope.row)"
                  style="cursor: pointer; color: #0c7de6">{{ scope.row.project_number }} /
                  {{ scope.row.team_number_total }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center"></el-table-column>
          </el-table>
          <el-table ref="clTable" :data="clTableData" width="100%" height="58.5vh" header-cell-class-name="table_header"
            v-if="clFocus" v-loading="clTableLoading">
            <el-table-column type="selection" key="checkbox" v-if="selectDeviceExport || selectDeviceDelete" width="100"
              align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.ComponentName')" prop="name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.uploadStatus')" prop="is_public" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_public == 0">{{ $t('messages.notUpload') }}</div>
                <div v-else>{{ $t('messages.alreadyUpload') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.Creator')" prop="display_name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.uploadTime')" prop="public_time" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.public_time">---</div>
                <div v-else>{{ scope.row.public_time }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Upload" placement="right">
                  <img alt="" v-if="scope.row.is_public == 0" @click="toUploadDevice(scope.row)" class="operateIcon"
                    src="../../assets/icons/devices/上传到器件库.svg" />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Update" placement="right">
                  <img alt="" v-if="scope.row.is_public == 1 && scope.row.is_update == 1"
                    @click="toUploadDevice(scope.row)" class="operateIcon"
                    src="../../assets/icons/devices/更新到器件库.svg" />
                </el-tooltip>
                <img alt="" v-if="scope.row.is_public == 1 && scope.row.is_update == 0" class="operateIcon disabled"
                  src="../../assets/icons/devices/更新到器件库2.svg" />
              </template>
            </el-table-column>
          </el-table>
          <el-table ref="symbolTable" :data="symbolTableData" width="100%" height="58.5vh"
            header-cell-class-name="table_header" v-if="symbolFocus" v-loading="stmbolTableLoading">
            <el-table-column type="selection" key="checkbox" v-if="selectDeviceExport || selectDeviceDelete" width="100"
              align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.SymbolName')" prop="name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.uploadStatus')" prop="is_public" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_public == 0">{{ $t('messages.notUpload') }}</div>
                <div v-else>{{ $t('messages.alreadyUpload') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.Creator')" prop="display_name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.uploadTime')" prop="public_time" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.public_time">---</div>
                <div v-else>{{ scope.row.public_time }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Upload" placement="right">
                  <img alt="" v-if="scope.row.is_public == 0" @click="toUploadSymbol(scope.row)" class="operateIcon"
                    src="../../assets/icons/devices/上传到器件库.svg" />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Update" placement="right">
                  <img alt="" v-if="scope.row.is_public == 1 && scope.row.is_update == 1"
                    @click="toUploadSymbol(scope.row)" class="operateIcon"
                    src="../../assets/icons/devices/更新到器件库.svg" />
                </el-tooltip>
                <img alt="" v-if="scope.row.is_public == 1 && scope.row.is_update == 0" class="operateIcon disabled"
                  src="../../assets/icons/devices/更新到器件库2.svg" />
              </template>
            </el-table-column>
          </el-table>
          <el-table ref="projectMemberTable" :data="projectMemberTableData" width="100%" height="58.5vh"
            header-cell-class-name="table_header" v-if="memberFocus" :key="projectMemberTableData.length"
            v-loading="projectMemberTableLoading">
            <el-table-column type="selection" key="checkbox"
              v-if="selectTeamPower || selectDevicePower || selectDeleteMember"
              :selectable="projectMemberTableSelectable" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" type="index" width="100" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.member')" prop="name" align="center" width="400">
              <template #default="scope">
                <div class="table-column__membername" :title="scope.row.name">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.remark')" prop="note" align="center" width="200">
              <template #default="scope">
                <div class="table-column__membername" :title="scope.row.note">{{ scope.row.note }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.teamRole')" prop="user_role" align="center">
              <template slot-scope="scope">
                <el-select class="table_select" v-model.trim="scope.row.user_role"
                  :placeholder="$t('messages.placeholder_0')"
                  v-if="scope.row.user_role == '协作者' || scope.row.user_role == '管理者'"
                  :disabled="currentUserRoleType == scope.row.user_role || currentUserRoleType !== '所有者' || selectTeamPower"
                  @change="userRoleSelectChange(scope.row)" v-loading="selectRoleLoading">
                  <el-option v-for="item in $t('messages.RoleOptions')" :key="item.value" :label="item.label"
                    :value="item.value">
                    <div v-if="item.label == '管理者'">
                      <strong>{{ item.label }}</strong>
                      <div class="options__note">{{ $t('messages.powerTips_1') }}</div>
                    </div>
                    <div v-if="item.label == '协作者'">
                      <strong>{{ item.label }}</strong>
                      <div class="options__note">{{ $t('messages.powerTips_2') }}</div>
                    </div>
                  </el-option>
                </el-select>
                <el-tag v-if="scope.row.user_role == '所有者'" type="owner">{{ $t('messages.owner') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.libraryPower')" prop="user_component_permission" align="center">
              <template slot-scope="scope">
                <div class="table_device_power"
                  v-if="(currentUserRoleId == 2 && alluserRoleInfo[scope.row.user_role] == 3) || (currentUserRoleId == 1 && alluserRoleInfo[scope.row.user_role] == 3)"
                  @click="updateSingleDevcicePower(scope.row)">
                  {{ transPowerType(scope.row.user_component_permission) }}
                </div>
                <div style="color: #333333" v-else>{{ transPowerType(scope.row.user_component_permission) }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <img alt=""
                  v-if="(currentUserRoleId == 1 && alluserRoleInfo[scope.row.user_role] == 3) || (currentUserRoleId == 1 && alluserRoleInfo[scope.row.user_role] == 2)"
                  @click="toEditMember(scope.row)" class="operateIcon" src="../../assets/icons/personnel/编辑.svg" />
                <img alt="" v-else class="operateIcon disabled" src="../../assets/icons/personnel/编辑2.svg" />
                <img alt="" @click="toDeleteSingleMember(scope.row)"
                  v-if="currentUserRoleId < alluserRoleInfo[scope.row.user_role]" class="operateIcon"
                  src="../../assets/icons/personnel/删除.svg" />
                <img alt="" v-else class="operateIcon disabled" src="../../assets/icons/personnel/删除2.svg" />
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <div class="t-item2" v-else>
      <div class="noTeam">
        <img alt="" class="noTeam-svg" src="../../assets/personalCenter/没有团队.svg" />
        <div class="noTeam-text">{{ $t('messages.noTeamInfo') }}</div>
      </div>
    </div>
    <el-dialog :title="$t('messages.createTeam')" :visible.sync="createTeamVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <span>{{ $t('messages.teamName') }}：</span>
        <el-input size="middle" v-model.trim="teamName" style="width: 88%" maxlength="10" show-word-limit
          @keyup.native="diykeyUp"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="createTeam"
          :disabled="!teamName.length > 0">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(createTeamVisible = false), (teamName = '')"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.updateInfo')" :visible.sync="updateInfoVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <span>{{ $t('messages.teamName') }}：</span>
        <el-input size="middle" v-model.trim="updateTeamName" style="width: 88%"
          :placeholder="$t('messages.placeholder_1')" maxlength="10" show-word-limit
          @keyup.native="diykeyUp"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateInfo" :disabled="!updateTeamName.length > 0">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click=";(updateInfoVisible = false), (updateTeamName = '')" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.inviteMember')" :visible.sync="addCooperaterVisible" width="480px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div class="searchForAddCooperater">
          <el-input v-model.trim="searchForAddCooperater" @keyup.enter.native="cooperaterDataScrollLoadSearch"
            class="selectClass" :placeholder="$t('messages.searchForKeyword')"></el-input>
        </div>
        <div id="device_list" class="infinite-list-wrapper" style="overflow-y: hidden; height: 400px"
          v-loading="cooperaterTableLoading">
          <ul class="list" style="overflow-y: auto; height: 100%" v-infinite-scroll="cooperaterDataScrollLoad"
            infinite-scroll-disabled="CooperaterDisabled">
            <li v-for="(i, k) in cooperaterTableData" class="list-item" :key="k">
              <div class="list-item__name" :title="i.display_name">
                {{ i.display_name }}
                <div style="display: inline-block" v-if="i.note">({{ i.note }})</div>
              </div>
              <div class="list-item__button">
                <el-checkbox v-model="i.selected"></el-checkbox>
              </div>
            </li>
          </ul>
          <p v-if="cooperaterTableNextPage" style="font-size: 12px; text-align: center; padding: 5px">加载中...</p>
          <p v-if="!cooperaterTableNextPage" style="font-size: 12px; text-align: center; padding: 5px">暂无更多数据</p>
        </div>
        <div></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem confirmFont">
          <div>{{ $t('messages.powerTips_3') }}</div>
        </div>
        <el-button type="sure" size="mini" @click="submitAddCooperater" :loading="AddCooperaterLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="cancleSubmitAddCooperater" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.addPerson')" :visible.sync="addMemberVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <span>{{ $t('messages.teamUrl') }}：</span>
          <div class="urlInfo">{{ teamUrl }}</div>
          <div class="copyUrlInfo">
            <el-tooltip class="item" effect="dark" content="copy" placement="right">
              <i class="el-icon-document-copy" @click="copyTeamUrl"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem confirmFont">
          <div>{{ $t('messages.powerTips_11_2') }}</div>
        </div>
        <el-button type="sure" size="mini" @click="addMemberVisible = false">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="addMemberVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.joinTeam')" :visible.sync="joinTeamVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <div style="margin-bottom: 20px; color: #333333">{{ $t('messages.powerTips_5') }}</div>
          <el-input class="selectClass" v-model.trim="joinTeamUrl" size="middle" style="width: 80%"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem confirmFont">
          <div>{{ $t('messages.powerTips_6') }}</div>
        </div>
        <el-button type="sure" size="mini" @click="submitJoinTeam" :disabled="!joinTeamUrl.length > 0">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="joinTeamVisible = false, joinTeamUrl = ''"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.cessionTeam')" :visible.sync="cessionTeamVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_7') }}
        </div>
        <div style="margin-top: 20px">
          <span>{{ $t('messages.cessionMember') }}：</span>
          <el-select class="selectClass" size="middle" style="width: 100%; margin-top: 10px"
            v-model.trim="selectTeamMember" :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in TeamMemberOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitCessionTeam" :disabled="!selectTeamMember.length > 0">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="cessionTeamVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.dismissTeam')" :visible.sync="dismissTeamVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_8') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="dismissTeamVisible = false">{{ $t('messages.sure') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.projectPermission')" :visible.sync="projectMemberPowerVisible" width="570px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <div style="margin-bottom: 20px; color: #333333">
            {{ $t('messages.powerTips_9_1') }}“{{ allRoleInfo[powerType] }}”,{{ $t('messages.powerTips_9_2') }}？</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateMemberOfProjectPower"
          :loading="UpdateMemberOfProjectPowerLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="projectMemberPowerVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.devicePower')" :visible.sync="projectMemberDevicePowerVisible" width="570px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <div style="margin-bottom: 20px; color: #333333">
            {{ $t('messages.powerTips_10_1') }}“{{ transPowerType(devicePowerType) }}”{{ $t('messages.powerTips_10_2') }},{{ $t('messages.powerTips_9_2') }}？
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateMemberDevicetPower"
          :loading="UpdateMemberDevicetPowerLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="projectMemberDevicePowerVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.devicePower')" :visible.sync="singleDevicePowerVisible" width="570px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <span>{{ $t('messages.power') }}：</span>
          <el-select class="selectClass" size="middle" style="width: 90%; margin-top: 10px" v-model.trim="devicePower"
            :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in DevicePowerOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitSingleDevicetPower" :loading="DevicetPowerLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="singleDevicePowerVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.editMemberInfo')" :visible.sync="memberEditVisible" width="570px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <div v-if="setRow">
            <div style="width: 90px; display: inline-block">{{ $t('messages.remark') }}：</div>
            <el-input class="selectClass" size="mini" v-model.trim="setRow.note" style="width: 80%" maxlength="9"
              show-word-limit oninput="value=value.replace(/\s+/g,'')"></el-input>
          </div>
          <div style="margin-top: 20px" v-if="setRow">
            <div style="width: 90px; display: inline-block">{{ $t('messages.teamRole') }}：</div>
            <el-input v-if="setRow.user_role == '所有者'" class="selectClass input-disabled" size="mini"
              v-model.trim="setRow.user_role" style="width: 80%" disabled></el-input>
            <el-select v-else class="selectClass" @change="editSelectChange(setRow)" size="middle" style="width: 80%"
              v-model.trim="setRow.user_role" :placeholder="$t('messages.placeholder_0')"
              :disabled="currentUserRoleId == 3">
              <el-option v-for="item in $t('messages.RoleOptions')" :key="item.value" :label="item.label"
                :value="item.value">
                <div v-if="item.label == '管理者'">
                  <strong>{{ item.label }}</strong>
                  <div class="options__note">{{ $t('messages.powerTips_1') }}</div>
                </div>
                <div v-if="item.label == '协作者'">
                  <strong>{{ item.label }}</strong>
                  <div class="options__note">{{ $t('messages.powerTips_4') }}</div>
                </div>
              </el-option>
            </el-select>
          </div>
          <div style="margin-top: 20px" v-if="setRow">
            <div style="width: 90px; display: inline-block">{{ $t('messages.libraryPower') }}：</div>
            <el-select :disabled="cantSelectDevicePower || currentUserRoleId == 3" class="selectClass" size="middle"
              style="width: 80%" v-model.trim="setRow.user_component_permission"
              :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in DevicePowerOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitMemberEdit" :loading="MemberEditLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="memberEditVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.share')" :visible.sync="shareProjectCodeVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <span>{{ $t('messages.shareUrl') }}：</span>
          <div class="urlInfo">{{ projectUrl }}</div>
          <div class="copyUrlInfo">
            <el-tooltip class="item" effect="dark" content="copy" placement="right">
              <i class="el-icon-document-copy" @click="shareProjectUrl"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem confirmFont">
          <div>{{ $t('messages.powerTips_11') }}</div>
        </div>
        <el-button type="sure" size="mini"
          @click="shareProjectCodeVisible = false">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="shareProjectCodeVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteProjectVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelete" :disabled="!dismissDeleteChecked"
          :loading="deleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteProjectVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteFileVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteFile" :disabled="!dismissDeleteChecked"
          :loading="deleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteFileVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteCellVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteCell" :disabled="!dismissDeleteChecked"
          :loading="deleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteCellVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delSingleMemberVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_14') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissTeamChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" :disabled="!dismissTeamChecked" @click="submitDelSingleMemberOfproject">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click=";(delSingleMemberVisible = false), (dismissTeamChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delMoreMemberVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_14') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissTeamChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="delMoreMemberVisible = true" :disabled="!dismissTeamChecked">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="delMoreMemberVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="quitTeamVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_15') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="quitTeamChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitQuitTeam"
          :disabled="!quitTeamChecked">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="quitTeamVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delMemberOfProjectVisible" width="575px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_16') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="quitTeamChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelMemberOfProject" :disabled="!quitTeamChecked"
          :loading="delMemberLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(delMemberOfProjectVisible = false), (quitTeamChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delDeviceVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_17') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteDeviceChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelDevice" :disabled="!deleteDeviceChecked"
          :loading="delDeviceLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(delDeviceVisible = false), (deleteDeviceChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delSymbolVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_17') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteDeviceChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelSymbol" :disabled="!deleteDeviceChecked"
          :loading="delSymbolLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(delSymbolVisible = false), (deleteDeviceChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToTeamVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 30px" class="selectbtype">
          <div class="item_title">{{ $t('messages.belong') }}：</div>
          <el-select class="selectClass must-left" size="middle" style="width: 82%" v-model.trim="selectCopyTeam"
            :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in TeamOptions" :key="item.value" :label="item.label" :value="item.value">
              <div v-if="item.value == 'personal'">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/用户信息.svg" />
                {{ item.label }}
              </div>
              <div v-else>
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/团队名.svg" />
                {{ item.label }}
              </div>
            </el-option>
          </el-select>
          <div v-if="selectCopyTeam">
            <img alt="" v-if="selectCopyTeam === 'personal'"
              style="height: 20px; position: absolute; left: 90px; top: 5px"
              src="../../assets/icons/personnel/用户信息.svg" />
            <img alt="" v-else style="height: 20px; position: absolute; left: 90px; top: 5px"
              src="../../assets/icons/personnel/团队名.svg" />
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="item_title">{{ $t('messages.name') }}：</div>
          <div style="display: inline-block; width: 85%; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitCopy" :disabled="!selectCopyTeam" :loading="copyLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="copyToTeamVisible = false, selectCopyTeam = ''"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToTeamProjectVisible" width="680px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 30px" class="selectbtype">
          <div class="item_title">{{ $t('messages.belong') }}：</div>
          <el-cascader class="selectManyClass must-left" size="middle" style="width: 82%" :options="TeamOptions"
            v-model="selectCopyTeam">
            <template slot-scope="{ node, data }">
              <div v-if="data.value == 'personal'" @click="getUserProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/用户信息.svg" />
                {{ data.label }}
              </div>
              <div v-else @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <!-- <el-select class="selectClass must-left" size="middle" style="width:82%;" v-model.trim="selectCopyTeam"
            :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in TeamOptions" :key="item.value" :label="item.label" :value="item.value">
              <div v-if="item.value=='personal'">
                <span class="el-icon-caret-bottom"></span>
                <img alt="" style="height: 20px;position: relative;top:5px;margin-right:5px;"
                  src="../../assets/icons/personnel/用户信息.svg" />{{item.label}}
                <div class="label-main">
                  <div class="label-main__item" v-for="k, v in labelPersonalProjectData" :key="k">{{v.name}}</div>
                </div>
              </div>
              <div v-else>
                <span class="el-icon-caret-bottom"></span>
                <img alt="" style="height: 20px;position: relative;top:5px;margin-right:5px;"
                  src="../../assets/icons/personnel/团队名.svg" />{{item.label}}
              </div>
            </el-option>
          </el-select> -->
          <div v-if="selectCopyTeam">
            <img alt="" v-if="selectCopyTeam === 'personal'"
              style="height: 20px; position: absolute; left: 90px; top: 11px"
              src="../../assets/icons/personnel/用户信息.svg" />
            <img alt="" v-else style="height: 20px; position: absolute; left: 90px; top: 11px"
              src="../../assets/icons/personnel/团队名.svg" />
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="item_title">{{ $t('messages.name') }}：</div>
          <div style="display: inline-block; width: 85%; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitFileCopy" :disabled="!selectCopyTeam"
          :loading="copyFileLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="copyToTeamProjectVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.teamRole')" :visible.sync="setTeamRoleVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div v-if="setRoleType == '协作者'" style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          <div>{{ $t('messages.powerTips_18') }}</div>
        </div>
        <div v-if="setRoleType == '管理者'" style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          <div>{{ $t('messages.powerTips_19') }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitSetTeamRole"
          :loading="teamRoleLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="setTeamRoleVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.teamRole')" :visible.sync="deleteMemberVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          <div>{{ $t('messages.powerTips_16') }}？</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteMemberChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteMember" :disabled="!deleteMemberChecked"
          :loading="deleteMemberLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteMemberVisible = false), (deleteMemberChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.teamRole')" :visible.sync="deleteSingleMemberVisible" width="575px"
      :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          <div>{{ $t('messages.powerTips_16') }}？</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteSingleMemberChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteSingleMember" :disabled="!deleteSingleMemberChecked"
          :loading="deleteMemberLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteSingleMemberVisible = false), (deleteSingleMemberChecked = false)"
          size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.projectPermission')" :visible.sync="batchProjectPowerVisible" :show-close="false"
      :modal="false">
      <div class="dialog-body2">
        <div class="btn-group">
          <button class="main-btn default" :class="{ clicked: setPowerType == 0 }"
            @click="updateProjectPower(0)">{{ $t('messages.inherit') }}</button>
          <button class="main-btn default" :class="{ clicked: setPowerType == 1 }"
            @click="updateProjectPower(1)">{{ $t('messages.editAndDelete') }}</button>
          <button class="main-btn default" :class="{ clicked: setPowerType == 3 }"
            @click="updateProjectPower(3)">{{ $t('messages.readOnly') }}</button>
          <button class="main-btn default" :class="{ clicked: setPowerType == 4 }"
            @click="updateProjectPower(4)">{{ $t('messages.invisible') }}</button>
        </div>
        <div style="display: flex">
          <div class="table-item">
            <el-input v-model.trim="searchForProjectMember" @keyup.enter.native="AllMemberOfTeamDataScrollLoadSearch"
              class="table-item__input" :placeholder="$t('messages.searchForKeyword')"></el-input>
            <!-- <el-table ref="powerProjectMembertable" :data="powerProjectMembertableData" :show-header="false" height="400" v-loading="Listloading">
              <el-table-column align="center" prop="name"></el-table-column>
              <el-table-column align="center" width="100" type="selection"></el-table-column>
            </el-table> -->
            <div class="infinite-list-wrapper" style="overflow-y: hidden; height: 400px" v-loading="AllMemberLoading">
              <ul class="list" style="overflow-y: auto; height: 100%" v-infinite-scroll="AllMemberOfTeamDataScrollLoad"
                infinite-scroll-disabled="memberDisabled">
                <li v-for="(i, k) in powerProjectMembertableData" class="list-item" :key="k">
                  <div class="list-item__name">
                    {{ i.display_name }}
                    <div style="display: inline-block" v-if="i.note">({{ i.note }})</div>
                  </div>
                  <div class="list-item__button">
                    <el-checkbox v-model="i.selected"></el-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="table-item">
            <!-- <el-input v-model.trim="searchForProjectPro" @keyup.enter.native="TeamProjectListDataScrollLoadSearch" class="table-item__input" :placeholder="$t('messages.searchForKeyword')"></el-input> -->
            <!-- <el-table ref="powerProjectProtable" :data="powerProjectProtableData" :show-header="false" height="400">
              <el-table-column align="center" prop="name"></el-table-column>
              <el-table-column align="center" width="100" type="selection"></el-table-column>
            </el-table> -->
            <div class="infinite-list-wrapper" style="overflow-y: auto; height: 400px">
              <div class="project-main">
                <div class="project-main__item" @click.stop="checkProjectCell(item_file)"
                  v-for="(item_file, key) in powerProjectProtableData" :key="key">
                  <div class="main-project">
                    <div class="project-main__item-icon">
                      <span class="el-icon-caret-bottom"></span>
                    </div>
                    <div class="project-main__item-name">
                      <span class="iconfont icon-xiangmuming"></span>
                      {{ item_file.name }}
                    </div>
                    <div class="project-main__item-select">
                      <el-checkbox v-model="item_file.selected" :disabled="item_file.no_permission"
                        @change="cellCellSelectedChange(item_file, 1)"></el-checkbox>
                    </div>
                  </div>
                  <el-collapse-transition>
                    <div v-show="item_file.show">
                      <div class="project-main__item-file" @click.stop="checkProjectCellCell(item_cell)"
                        v-for="(item_cell, key) in item_file.children" :key="key">
                        <div class="main-file">
                          <div class="project-main__item-icon" v-if="item_cell.types != null">
                            <span class="el-icon-caret-bottom"></span>
                          </div>
                          <div class="project-main__item-name">
                            <span class="iconfont icon-yuanlituwenjianmingcheng" v-if="item_cell.types == 3"></span>
                            <span class="iconfont icon-bantuwenjian" v-else></span>
                            {{ item_cell.name }}
                          </div>
                          <div class="project-main__item-select">
                            <el-checkbox v-model="item_cell.selected" :disabled="item_cell.no_permission"
                              @change="cellCellSelectedChange(item_cell, 2)"></el-checkbox>
                          </div>
                        </div>
                        <div v-show="item_cell.show">
                          <div class="project-main__item-cell" @click.stop=""
                            v-for="(item_cell_cell, key) in item_cell.children" :key="key">
                            <div class="main-cell">
                              <!-- <div class="project-main__item-icon">
                                <span class="el-icon-caret-bottom"></span>
                              </div> -->
                              <div class="project-main__item-name">
                                <span class="iconfont icon-yuanlitu" v-if="item_cell_cell.types == 3"></span>
                                <span class="iconfont icon-qijianming" v-else-if="item_cell_cell.types == 2"></span>
                                <span class="iconfont icon-dakaifuhao" v-else-if="item_cell_cell.types == 4"></span>
                                <span class="iconfont icon-dakaifuhao" v-else-if="item_cell_cell.types == 5"></span>
                                <span class="iconfont icon-bantuming" v-else></span>
                                {{ item_cell_cell.name }}
                              </div>
                              <div class="project-main__item-select">
                                <el-checkbox v-model="item_cell_cell.selected" :disabled="item_cell_cell.no_permission"
                                  @change="cellCellSelectedChange(item_cell_cell, 3)"></el-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </div>
              <!-- <ul class="list" style="overflow-y:auto;height:100%;" v-infinite-scroll="TeamProjectListDataScrollLoad"
                infinite-scroll-disabled="projectDisabled">
                <li v-for="i, k in powerProjectProtableData" class="list-item" :key="k">
                  <div class="list-item__name">{{ i.name }}</div>
                  <div class="list-item__button">
                    <el-checkbox v-model="i.selected"></el-checkbox>
                  </div>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="mini" @click="submitUpdateProjectPower(false)" :disabled="setPowerType == null"
          :loading="projectPowerLoading">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" size="mini" @click="submitUpdateProjectPower(true)" :disabled="setPowerType == null"
          :loading="projectPowerLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="cancleUpdateProjectPower" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.uploadTo')" :visible.sync="uploadDeviceVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body3">
        <el-form ref="deviceForm" :model="deviceForm">
          <el-form-item prop="name">
            <span slot="label">
              <span class="must">{{ $t('messages.name') }}：</span>
            </span>
            <el-input v-model.trim="deviceForm.name" size="middle" class="selectClass" style="width: 85%" clearable
              :placeholder="$t('messages.placeholder_1')" maxlength="16" @keyup.native="diykeyUp" disabled></el-input>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.Label') }}：</span>
            </span>
            <div class="form-label">
              <div class="form-labrl__item" v-for="(v, k) in deviceForm.label" :key="k">
                <el-input size="middle" @blur="labelkeyUp(k)" @keyup.native="diykeyUp" v-model="deviceForm.label[k]"
                  maxlength="10" show-word-limit class="selectClass" style="width: 90%"></el-input>
                <div v-if="k == 0" @click="addDeviceLabel" class="addLabel"><span class="el-icon-circle-plus"></span>
                </div>
                <div v-else @click="delDeviceLabel(k)" class="delLabel"><span class="el-icon-remove-outline"></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.note') }}：</span>
            </span>
            <div style="margin-top: 10px">
              <el-input type="textarea" :placeholder="$t('messages.optional')" :rows="6" style="width: 85%"
                maxlength="128" show-word-limit v-model="deviceForm.note"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUploadDevice">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(uploadDeviceVisible = false), resetForm('deviceForm')" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.uploadSymbolTo')" :visible.sync="uploadSymbolVisible" width="575px"
      :show-close="false" :modal="false">
      <div class="dialog-body3">
        <el-form ref="deviceForm" :model="deviceForm">
          <el-form-item prop="name">
            <span slot="label">
              <span class="must">{{ $t('messages.name') }}：</span>
            </span>
            <el-input v-model.trim="deviceForm.name" size="middle" class="selectClass" style="width: 85%" clearable
              :placeholder="$t('messages.placeholder_1')" maxlength="16" @keyup.native="diykeyUp" disabled></el-input>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.Label') }}：</span>
            </span>
            <div class="form-label">
              <div class="form-labrl__item" v-for="(v, k) in deviceForm.label" :key="k">
                <el-input size="middle" @blur="labelkeyUp(k)" @keyup.native="diykeyUp" v-model="deviceForm.label[k]"
                  maxlength="10" show-word-limit class="selectClass" style="width: 90%"></el-input>
                <div v-if="k == 0" @click="addDeviceLabel" class="addLabel"><span class="el-icon-circle-plus"></span>
                </div>
                <div v-else @click="delDeviceLabel(k)" class="delLabel"><span class="el-icon-remove-outline"></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.note') }}：</span>
            </span>
            <div style="margin-top: 10px">
              <el-input type="textarea" :placeholder="$t('messages.optional')" :rows="6" style="width: 85%"
                maxlength="128" show-word-limit v-model="deviceForm.note"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUploadSymbol">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(uploadSymbolVisible = false), resetForm('deviceForm')" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.batchAddComponent')" :visible.sync="batchAddComponentVisible" width="60%"
      :show-close="false" :modal="false" @open="searchStart">
      <div class="dialog-body-search">
        <div class="search-header">
          <el-select style="width: 200px" class="componentSelect" size="mini" @change="toSearchComponent"
            v-model="search_library_type">
            <el-option v-for="item in $t('messages.batchAddComponentTypeOptions')" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" v-if="search_library_type == 0" class="componentSelect" size="mini"
            v-model="search_library_id" @change="toSearchComponent">
            <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <!-- <el-select style="width: 200px;" v-if="search_library_type==4" class="componentSelect" size="mini" v-model="search_project_id">
            <el-option v-for="item in projectOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-cascader style="width: 200px" v-if="search_library_type == 4" class="componentSelect" size="mini"
            v-model="search_project_id" :options="projectOptionList">
            <template slot-scope="{ node, data }">
              <div @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <el-select style="width: 200px" v-if="search_library_type == 1" class="componentSelect" size="mini"
            v-model="search_team_id" @change="toSearchComponent">
            <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" class="componentSelect" size="mini" v-model="search_component_type"
            v-show="false">
            <el-option v-for="item in $t('messages.componentOptions')" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-input style="width: 200px" size="mini" v-model="searchComponentName"
            :placeholder="$t('messages.placeholder_seach_component')"></el-input>
          <el-button size="mini" type="primary" @click="toSearchComponent">{{ $t('messages.search') }}</el-button>
        </div>
        <div class="search-body">
          <el-table ref="searchComponentTable" v-loading="searchComponentLoading" :data="searchComponentTableData"
            height="60vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" width="50" align="center"></el-table-column>
            <el-table-column type="index" width="100" align="center" label="序号"></el-table-column>
            <el-table-column label="器件名称" align="left" prop="name"></el-table-column>
            <el-table-column label="器件类型" width="200" align="left" prop="cell_component_type">
              <template slot-scope="scope">
                <div v-if="scope.row.cell_component_type == 1">{{ $t('messages.fixedDevices') }}</div>
                <div v-else>{{ $t('messages.parameterizedDevice') }}</div>
              </template>
            </el-table-column>
            <el-table-column v-if="search_library_type == 1 || search_library_type == 2" label="创建者" width="200"
              align="left" prop="display_name"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" :loading="replaceLoading" @click="submitBatchAddComponent(false)"
          size="mini">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" :loading="replaceLoading" size="mini"
          @click="submitBatchAddComponent(true)">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" :loading="replaceLoading" @click="cancelSubmitAdd"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.batchAddSymbol')" :visible.sync="batchAddSymbolVisible" width="60%"
      :show-close="false" :modal="false" @open="searchStart">
      <div class="dialog-body-search">
        <div class="search-header">
          <el-select style="width: 200px" class="componentSelect" size="mini" @change="toSearchSymbol"
            v-model="search_library_type">
            <el-option v-for="item in $t('messages.batchAddSymbolTypeOptions')" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" v-if="search_library_type == 0" class="componentSelect" size="mini"
            v-model="search_library_id" @change="toSearchSymbol">
            <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <!-- <el-select style="width: 200px;" v-if="search_library_type==4" class="componentSelect" size="mini" v-model="search_project_id">
            <el-option v-for="item in projectOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-cascader style="width: 200px" v-if="search_library_type == 4" class="componentSelect" size="mini"
            v-model="search_project_id" :options="projectOptionList">
            <template slot-scope="{ node, data }">
              <div @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px"
                  src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <el-select style="width: 200px" v-if="search_library_type == 1" class="componentSelect" size="mini"
            v-model="search_team_id" @change="toSearchSymbol">
            <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-input style="width: 200px" size="mini" v-model="searchComponentName"
            :placeholder="$t('messages.placeholder_seach_symbol')"></el-input>
          <el-button size="mini" type="primary" @click="toSearchSymbol">{{ $t('messages.search') }}</el-button>
        </div>
        <div class="search-body">
          <el-table ref="searchSymbolTable" v-loading="searchSymbolLoading" :data="searchSymbolTableData" height="60vh"
            header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" width="50" align="center"></el-table-column>
            <el-table-column type="index" width="200" align="left" label="序号"></el-table-column>
            <el-table-column label="符号名称" align="left" prop="name"></el-table-column>
            <el-table-column v-if="search_library_type == 1 || search_library_type == 2" label="创建者" width="200"
              align="left" prop="display_name"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" :loading="replaceLoading" @click="submitBatchAddSymbol(false)"
          size="mini">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" :loading="replaceLoading" size="mini"
          @click="submitBatchAddSymbol(true)">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" :loading="replaceLoading" @click="cancelSubmitAdd"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsCellNameVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsNameTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsCellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitBatchAddComponent(false, 1)">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceCellName"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsSymbolNameVisible" width="575px" :show-close="false"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsNameTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsCellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitBatchAddSymbol(false, 1)">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceCellName"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <div class="errWindow">
      <el-dialog :modal="false" :title="errTitle" :visible.sync="errDialogVisible" :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px"
            :class="isError ? 'iconfont icon-sousuoshanchu' : 'iconfont icon-jinggao'"></i>
          <span>{{ errInfo }}</span>
          <br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="errDialogVisible = false"
              size="mini">{{ $t('messages.sure') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  getTeamList,
  createTeams,
  getTeamProjectList,
  transferTeam,
  getTeamInciteCode,
  getTeamInviteConfirm,
  getMemberOfTeam,
  quitTeam,
  getMemberOfTeamProject,
  getUserRoleTeamProject,
  getAllCooperater,
  addCooperater,
  delMember,
  updateMemberPower,
  getUserRole,
  batchCopyProject,
  batchCopyProjectToPerson,
  getShareTeamProjectCode,
  updateTeamName,
  getProjectPermissions,
  deleteProject,
  batchTeamRole,
  batchProjectPermission,
  batchDeleteMember,
  batchDevicePower,
  updateMemberEdit,
  uploadDevice,
  getTeamDevice, //NOSONAR
  dissolutionTeam,
  deleteDevice,
  uploadSymbol,
  deleteSymbol,
  getTeamViewer,
  getTeamViewerNote,
  getTeamSymbol,
  copyFiles,
  deleteFiles,
  getLibraryList,
  getSymbolList,
  getPersonalLibraryComponent,
  getPersonalLibrarySymbol,
  batchAddTeamComponent,
  batchAddTeamSymbol,
  getTeamLibrarySymbol,
  getPermissionOfLibrary,
  getTeamLibraryComponent,
  getPublicLibraryComponent,
  getOfficialLibraryComponent,
  getPDKLibraryComponent,
  getPDKLibrarySymbol,
  getPublicLibrarySymbol,
} from "@/api/api";
import {
  getTeamListApi, //NOSONAR
  createTeamsApi, //NOSONAR
  getTeamProjectListApi, //NOSONAR
  transferTeamApi, //NOSONAR
  getTeamInciteCodeApi, //NOSONAR
  getTeamInviteConfirmApi, //NOSONAR
  getMemberOfTeamApi, //NOSONAR
  quitTeamApi, //NOSONAR
  getMemberOfTeamProjectApi, //NOSONAR
  getUserRoleTeamProjectApi, //NOSONAR
  getAllCooperaterApi, //NOSONAR
  addCooperaterApi, //NOSONAR
  delMemberApi, //NOSONAR
  updateMemberPowerApi, //NOSONAR
  getUserRoleApi, //NOSONAR
  batchCopyProjectApi, //NOSONAR
  batchCopyProjectToPersonApi, //NOSONAR
  getShareTeamProjectCodeApi, //NOSONAR
  updateTeamNameApi, //NOSONAR
  getProjectPermissionsApi, //NOSONAR
  deleteProjectApi, //NOSONAR
  batchTeamRoleApi, //NOSONAR
  batchProjectPermissionApi, //NOSONAR
  batchDeleteMemberApi, //NOSONAR
  batchDevicePowerApi, //NOSONAR
  updateMemberEditApi, //NOSONAR
  uploadDeviceApi, //NOSONAR
  getTeamDeviceApi,
  dissolutionTeamApi, //NOSONAR
} from "./teamInfo";
import {
  getBlobFile,
  getFileList_api,
  getCellList_api,
  searchFile_api,
  getUserProjectList_api,
  getTeamProjectList_api,
  getTeamList_api,
} from "@/api/file/file.js";
import { deepClone, toFirst } from "../../utils/utils";
import {
  batchProjectCopyTo,
  batchFileCopyTo,
  projectShareTo,
  fileShareTo,
} from "./filePublicFn";
import {
  getObjectDataFn,
  downloadFileData,
} from "@/components/homes/fileList/function/objectStorageFn";
import bus from "../common/bus";
export default {
  data() {
    return {
      setRow: null,
      checkMember: false,
      checkPower: false,
      checkFile: false,
      checkProject: "",
      createTeamVisible: false,
      joinTeamVisible: false,
      addMemberVisible: false,
      cessionTeamVisible: false,
      dismissTeamVisible: false,
      delSingleMemberVisible: false,
      delMoreMemberVisible: false,
      quitTeamVisible: false,
      addCooperaterVisible: false,
      delMemberOfProjectVisible: false,
      projectMemberPowerVisible: false,
      copyToTeamVisible: false,
      copyToTeamProjectVisible: false,
      shareProjectCodeVisible: false,
      updateInfoVisible: false,
      deleteProjectVisible: false,
      deleteFileVisible: false,
      deleteCellVisible: false,
      setTeamRoleVisible: false,
      deleteMemberVisible: false,
      batchProjectPowerVisible: false,
      deleteSingleMemberVisible: false,
      projectMemberDevicePowerVisible: false,
      singleDevicePowerVisible: false,
      memberEditVisible: false,
      delDeviceVisible: false,
      delSymbolVisible: false,
      uploadDeviceVisible: false,
      uploadSymbolVisible: false,
      dismissDeleteChecked: false,
      deleteMemberChecked: false,
      deleteSingleMemberChecked: false,
      deleteDeviceChecked: false,
      selectCopyTeam: "",
      updateTeamName: "",
      selectShare: false,
      selectCopy: false,
      selectDelete: false,
      selectTeamPower: false,
      selectDevicePower: false,
      selectDeleteMember: false,
      selectDeviceExport: false,
      selectDeviceDelete: false,
      TeamOptions: [],
      selectTeamList: [],
      selectMemberList: [],
      selectDeviceList: [],
      selectTeam: "",
      selectCopyType: "team",
      selectTeamMember: "",
      teamUrl: "",
      projectUrl: "",
      joinTeamUrl: "",
      teamName: "",
      dismissTeamChecked: false,
      quitTeamChecked: false,
      searchTeamName: "",
      start: 0,
      rows: 10,
      teamData: [],
      currentTeam: 0,
      projectTableData: [],
      clTableData: [],
      memberTableData: [],
      RoleOptions: [
        {
          label: "管理者",
          value: "管理者",
        },
        {
          label: "协作者",
          value: "协作者",
        },
      ],
      allRoleInfo: {
        1: "可删除、编辑",
        2: "编辑",
        3: "只读",
      },
      alluserRoleInfo: {
        所有者: 1,
        管理者: 2,
        协作者: 3,
      },
      allDevicePowerInfo: {
        只读: 1,
        可使用: 2,
        可编辑: 3,
      },
      DevicePowerOptions: [
        {
          label: "只读",
          value: 1,
        },
        {
          label: "可使用",
          value: 2,
        },
        {
          label: "可编辑",
          value: 3,
        },
      ],
      devicePower: "",
      devicePowerType: null,
      cantSelectDevicePower: false,
      projectMemberTableData: [],
      cooperaterTableData: [],
      powerProjectMembertableData: [],
      powerProjectProtableData: [],
      searchForProjectMember: "",
      searchForProjectPro: "",
      projectFocus: true,
      clFocus: false,
      symbolFocus: false,
      memberFocus: false,
      memberTableDatabatchDelete: false,
      TeamMemberOptions: [],
      searchProjectName: "",
      searchMemberOfProject: "",
      searchMemberOfTeamProject: "",
      searchForAddCooperater: "",
      waitDeleteMemberOfprojectData: null,
      pageSize: 20,
      total: 0,
      memberCurrentPage: 1,
      memberPageSize: 20,
      memberTotal: 0,
      currentPage: 1,
      filePageSize: 20,
      cellPageSize: 20,
      powerType: "",
      currentUserRoleType: "",
      currentUserRoleId: "",
      currentUserId: "",
      setRoleType: "协作者",
      setPowerType: null,
      setDeleteMember: null,
      scrollStart_1: 0,
      scrollRows_1: 100,
      scrollStart_2: 0,
      scrollRows_2: 100,
      searchDevice: "",
      searchSymbol: "",
      loading: false,
      Listloading: false,
      AllMemberNextPage: true,
      AllMemberLoading: false,
      TeamProjectNextPage: true,
      TeamProjectLoading: false,
      teamLoading: false,
      teamRoleLoading: false,
      deleteMemberLoading: false,
      projectPowerLoading: false,
      copyLoading: false,
      selectRoleLoading: false,
      memberTableLoading: false,
      projectMemberTableLoading: false,
      projectTableLoading: false,
      clTableLoading: false,
      cooperaterTableNextPage: true,
      cooperaterTableStart: 0,
      cooperaterTableRows: 20,
      cooperaterTableLoading: false,
      AddCooperaterLoading: false,
      UpdateMemberOfProjectPowerLoading: false,
      delMemberLoading: false,
      delDeviceLoading: false,
      delSymbolLoading: false,
      MemberEditLoading: false,
      deleteLoading: false,
      DevicetPowerLoading: false,
      UpdateMemberDevicetPowerLoading: false,
      AllMemberSearch: false,
      TeamProjectSearch: false,
      CooperaterSearch: false,
      deviceForm: {
        name: "",
        label: [],
        mote: "",
        snow_id: "",
      },
      file_loading: false,
      cell_loading: false,
      cell_cell_loading: false,
      powerDataSet: { project_list: [], file_list: [], cell_list: [] },
      fileTableData: [],
      cellTableData: [],
      fileSearchName: "",
      cellSearchName: "",
      fileTotal: 0,
      fileCurrentPage: 1,
      cellCurrentPage: 1,
      cellTotal: 0,
      checkCell: false,
      symbolTableData: [],
      stmbolTableLoading: false,
      selectTypes: 1,
      copyFileLoading: false,
      checkProjectFileName: "",
      batchAddComponentVisible: false,
      batchAddSymbolVisible: false,
      searchComponentName: "",
      search_component_type: 1,
      search_project_id: "",
      search_library_type: 0,
      search_library_id: "",
      search_team_id: "",
      projectOptionList: [],
      teamOptionList: [],
      searchComponentLoading: false,
      searchSymbolLoading: false,
      searchComponentTableData: [],
      searchSymbolTableData: [],
      libraryOptions: [],
      search_start: 0,
      search_rows: 200,
      select_component_obj: {},
      select_component_index: 0,
      is_cover: 0,
      search_symbol_type: 0,
      component_permission: 0,
      symbol_permission: 0,
      existsCellNameVisible: false,
      existsSymbolNameVisible: false,
      existsCellList: [],
      replaceLoading: false,
      //错误弹窗
      errDialogVisible: false,
      errTitle: "",
      errInfo: "",
      isError: true,
    };
  },
  computed: {
    memberDisabled() {
      return this.AllMemberSearch;
    },
    projectDisabled() {
      return this.TeamProjectSearch;
    },
    CooperaterDisabled() {
      return this.CooperaterSearch;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    errDialog(title, info, err) {
      this.errTitle = title;
      this.errInfo = info;
      this.isError = err;
      this.errDialogVisible = true;
    },
    async searchStart() {
      this.teamOptionList = [];
      this.projectOptionList = await this.getAllProjectSelectOptions();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resetSelection(tableName) {
      try {
        this.$refs[tableName].clearSelection();
      } catch {
        return;
      }
    },
    transPowerType(type) {
      if (type == "可使用") {
        return this.$t("messages.canUsed");
      } else if (type == "可编辑") {
        return this.$t("messages.canEdit");
      } else if (type == "只读") {
        return this.$t("messages.readOnly");
      }
    },
    transRoleType(type) {
      if (type == "管理者") {
        return this.$t("messages.manager");
      } else if (type == "协作者") {
        return this.$t("messages.collaborator");
      }
    },
    keyUp(e) {
      e.target.value = e.target.value.replace(
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
        ""
      );
      e.target.value = e.target.value.replace(/[^\x00-\xff]/g, ""); //NOSONAR
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
    },
    diykeyUp(e) {
      e.target.value = e.target.value.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
        ""
      );
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
      this.teamName = e.target.value;
      this.updateTeamName = e.target.value;
    },
    labelkeyUp(index) {
      let str = deepCopy(this.deviceForm.label[index]);
      str = str.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
        ""
      );
      str = str.replace(/\ +/g, "");
      str = str.replace(/[\r\n]/g, "");
      this.deviceForm.label[index] = str;
    },
    addDeviceLabel() {
      if (this.deviceForm.label.length > 15) {
        this.$message.error(this.$t("messages.error_8"));
      } else {
        if (this.deviceForm.label[this.deviceForm.label.length - 1]) {
          this.deviceForm.label.push("");
        } else {
          this.$message.error(this.$t("messages.error_7"));
        }
      }
    },
    delDeviceLabel(index) {
      if (this.deviceForm.label.length < 2) {
        this.$message.error(this.$t("messages.error_6"));
      } else {
        this.deviceForm.label.splice(index, 1);
      }
    },
    toBatchPower() {
      this.checkPower = true;
    },
    toMemberTableDatabatchDelete() {
      this.memberTableDatabatchDelete = true;
    },
    cancelMemberTableDatabatchDelete() {
      this.memberTableDatabatchDelete = false;
      this.resetSelection("memberTable");
    },
    clickMemberTableDatabatchDelete() {
      this.delMemberOfProjectVisible = true;
    },
    memberTableSelectable(row, index) {
      return row.is_select;
    },
    projectTableSelectable(row, index) {
      if (this.selectDelete) {
        return row.can_delete;
      } else {
        return true;
      }
    },
    projectMemberTableSelectable(row, index) {
      if (this.selectDevicePower && row.user_role === "管理者") {
        return false;
      }
      if (
        row.user_role !== "所有者" &&
        this.currentUserRoleType !== row.user_role
      ) {
        return true;
      }
      return false;
    },
    initSelectStatus() {
      this.selectShare = false;
      this.selectCopy = false;
      this.selectDelete = false;
      this.selectTeamPower = false;
      this.selectDevicePower = false;
      this.selectDeleteMember = false;
      this.selectDeviceExport = false;
      this.selectDeviceDelete = false;
    },
    teamChange(index) {
      this.teamData[0].selected = false;
      this.teamData[this.currentTeam].selected = false;
      this.teamData[index].selected = true;
      this.component_permission = this.teamData[index].library_permission;
      this.currentTeam = index;
      const ob = this.teamData[index];
      this.checkMember = false;
      this.checkFile = false;
      this.checkCell = false;
      this.getUserRoleData();
      this.initSelectStatus();
      if (this.projectFocus) {
        this.currentPage = 1;
        this.pageSize = 20;
        this.getTeamProjectListData(0, this.pageSize, ob);
      } else if (this.clFocus) {
        this.currentPage = 1;
        this.pageSize = 20;
        this.getDeviceListData();
      } else if (this.memberFocus) {
        this.currentPage = 1;
        this.pageSize = 20;
        this.getMemberOfTeamData(0, this.pageSize, ob);
      } else if (this.symbolFocus) {
        this.currentPage = 1;
        this.pageSize = 20;
        this.getTeamSymbolData(0, this.pageSize, ob);
      }
    },
    vChange(index) {
      this.currentPage = 1;
      this.pageSize = 20;
      this.initSelectStatus();
      if (index === 0) {
        this.projectTableData = [];
        this.projectFocus = true;
        this.clFocus = false;
        this.memberFocus = false;
        this.symbolFocus = false;
        this.getTeamProjectListData(
          0,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else if (index === 1) {
        this.clTableData = [];
        this.projectFocus = false;
        this.clFocus = true;
        this.memberFocus = false;
        this.symbolFocus = false;
        this.getLibraryInfo();
        this.getDeviceListData();
      } else if (index === 2) {
        this.clTableData = [];
        this.projectFocus = false;
        this.clFocus = false;
        this.memberFocus = false;
        this.symbolFocus = true;
        this.getLibraryInfo();
        this.getTeamSymbolData(
          0,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else {
        this.memberTableData = [];
        this.projectFocus = false;
        this.clFocus = false;
        this.memberFocus = true;
        this.symbolFocus = false;
        this.getMemberOfTeamData(
          0,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      }
    },
    returnTeam() {
      this.checkMember = false;
      this.checkPower = false;
      this.checkFile = false;
      this.checkCell = false;
      this.getTeamProjectListData(
        0,
        this.pageSize,
        this.teamData[this.currentTeam]
      );
    },
    returnFile() {
      this.checkMember = false;
      this.checkPower = false;
      this.checkFile = true;
      this.checkCell = false;
    },
    checkMembers(row) {
      setTimeout(() => {
        if (row.is_permission) {
          this.checkMember = true;
          this.checkFile = false;
          this.checkCell = false;
          this.checkProject = row.name;
          this.checkProjectGNS = row.gns;
          this.currentPage = 1;
          const start = (this.currentPage - 1) * this.memberPageSize;
          this.getMemberOfTeamProjectData(
            start,
            this.memberPageSize,
            this.checkProjectGNS
          );
        } else {
          this.$message.error(this.$t("messages.error_5"));
        }
      }, 100);
    },
    checkFiles(row) {
      setTimeout(() => {
        this.checkFile = true;
        this.checkMember = false;
        this.checkCell = false;
        this.checkProject = row.name;
        this.checkProjectFileName = row.name;
        this.checkProjectGNS = row.gns;
        this.fileCurrentPage = 1;
        const start = (this.fileCurrentPage - 1) * this.filePageSize;
        this.getFileListData(start, this.filePageSize, this.checkProjectGNS);
      }, 100);
    },
    checkCells(row) {
      setTimeout(() => {
        this.checkFile = false;
        this.checkCell = true;
        this.checkMember = false;
        this.checkProject = row.name;
        this.checkProjectGNS = row.gns;
        this.cellCurrentPage = 1;
        this.selectTypes = row.types;
        const start = (this.cellCurrentPage - 1) * this.cellPageSize;
        this.getCellListData(start, this.filePageSize, this.checkProjectGNS);
      }, 100);
    },
    async getTeamListData(start, rows, keyword = "") {
      let params = {
        start: start,
        rows: rows,
        key_word: keyword,
      };
      this.currentTeam = 0;
      await getTeamList(params).then((res) => {
        if (res.code / 1000 === 200) {
          let team_list = [];
          this.teamData = [];
          if (res.data.data.length > 0) {
            team_list = res.data.data.map((item) => {
              const ob = { selected: false };
              return Object.assign(item, ob);
            });
            this.TeamOptions = team_list.map((item) => {
              const dicts = {
                label: item.team_name,
                value: item.snow_team_id,
                gns: item.gns,
                snow_id: item.snow_team_id,
                children: [],
              };
              return dicts;
            });
            this.TeamOptions.unshift({
              label:
                localStorage.getItem("userName") +
                this.$t("messages.powerTips_28"),
              value: "personal",
              children: [],
            });
            this.teamData = team_list;
            this.teamData[0].selected = true;
            this.start = res.data.next_start;
            this.component_permission = this.teamData[0].library_permission;
            this.getTeamProjectListData(0, this.pageSize, this.teamData[0]);
            this.getUserRoleData();
            this.resetSelection("projectTable");
            // getPermissionOfLibrary({team_snow_id: this.teamData[0].snow_team_id}).then(power_res => {
            //   this.component_permission = power_res.data.component_permission;
            //   this.symbol_permission = power_res.data.symbol_permission;
            // });
          }
        } else {
          // this.$message.error(res.message);
        }
        this.teamLoading = false;
      });
    },
    createTeam() {
      if (!this.teamName) {
        return;
      }
      let params = {
        team_name: this.teamName,
      };
      createTeams(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.getTeamListData(0, 100);
          this.teamName = "";
          this.updateTeamName = "";
          this.createTeamVisible = false;
        }
      });
    },
    getTeamProjectListData(start, rows, ob) {
      let params = {
        start: start,
        rows: rows,
        snow_team_id: ob.snow_team_id,
        team_gns: ob.gns,
        key_word: this.searchProjectName,
      };
      this.projectTableLoading = true;
      getTeamProjectList(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectTableData = res.data.data.map((item) => {
            const ob = { can_delete: false, is_permission: false };
            return Object.assign(item._source, ob);
          });
          this.total = res.data.total;
          this.getProjectPermissionsData();
        } else {
          // this.$message.error(res.message);
        }
        this.projectTableLoading = false;
      });
    },
    getTeamSymbolData(start, rows, ob) {
      let params = {
        start: start,
        rows: rows,
        team_id: ob.snow_team_id,
        team_gns: ob.gns,
        key_words: this.searchSymbol,
      };
      this.stmbolTableLoading = true;
      getTeamSymbol(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.symbolTableData = res.data.data.map((item) => {
              const ob = { can_delete: false, is_permission: false };
              return Object.assign(item, ob);
            });
            this.total = res.data.total;
            this.getProjectPermissionsData();
          } else {
            // this.$message.error(res.message);
          }
          this.stmbolTableLoading = false;
        })
        .catch(() => {
          this.symbolTableData = [];
          this.stmbolTableLoading = false;
        });
    },
    serarchTeam() {
      this.start = 0;
      this.currentTeam = 0;
      this.teamLoading = true;
      this.getTeamListData(0, 100, this.searchTeamName);
    },
    toCessionTeam() {
      this.cessionTeamVisible = true;
      this.getAllMemberOfTeamData(this.teamData[this.currentTeam]);
    },
    async toDismissTeam() {
      if (this.projectTableData.length > 0 || this.clTableData.length > 0) {
        this.dismissTeamVisible = true;
      } else {
        const params = {
          snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        };
        dissolutionTeam(params).then((res) => {
          if (res.code / 1000 === 200) {
            window.location.reload();
          } else {
            // this.$message.error(res.message);
          }
        });
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      const start = (this.currentPage - 1) * this.pageSize;
      if (this.projectFocus) {
        this.getTeamProjectListData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else if (this.clFocus) {
        this.getDeviceListData();
      } else if (this.memberFocus) {
        this.getMemberOfTeamData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else if (this.symbolFocus) {
        this.getTeamSymbolData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      const start = (this.currentPage - 1) * this.pageSize;
      if (this.projectFocus) {
        this.getTeamProjectListData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else if (this.clFocus) {
        this.this.getDeviceListData();
      } else if (this.memberFocus) {
        this.getMemberOfTeamData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      } else if (this.symbolFocus) {
        this.getTeamSymbolData(
          start,
          this.pageSize,
          this.teamData[this.currentTeam]
        );
      }
    },
    getMemberOfTeamData(start, rows, ob) {
      let params = {
        start: start,
        rows: rows,
        snow_team_id: ob.snow_team_id,
        team_gns: ob.gns,
        key_word: this.searchMemberOfProject,
      };
      this.projectMemberTableLoading = true;
      getMemberOfTeam(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectMemberTableData = res.data.data
            .map((item) => {
              const ob = {
                can_delete: true,
                is_permission: false,
                user_role_id: this.alluserRoleInfo[item.user_role],
              };
              return Object.assign(item, ob);
            })
            .sort((a, b) => {
              return a.user_role_id - b.user_role_id;
            });
          this.total = res.data.total;
          this.TeamMemberOptions = res.data.data.map((item) => {
            return { label: item.name, value: item.user_id };
          });
        } else {
          // this.$message.error(res.message);
        }
        this.projectMemberTableLoading = false;
      });
    },
    getAllMemberOfTeamData(ob) {
      let params = {
        start: 0,
        rows: 100,
        snow_team_id: ob.snow_team_id,
        team_gns: ob.gns,
        key_word: "",
      };
      getMemberOfTeam(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.TeamMemberOptions = res.data.data.map((item) => {
            return { label: item.name, value: item.user_id };
          });
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    searchMemberOfProjectBtn() {
      this.currentPage = 1;
      const start = (this.currentPage - 1) * this.pageSize;
      this.getMemberOfTeamData(
        start,
        this.pageSize,
        this.teamData[this.currentTeam]
      );
    },
    searchSymbolClick() {
      this.currentPage = 1;
      const start = (this.currentPage - 1) * this.pageSize;
      this.getTeamSymbolData(
        start,
        this.pageSize,
        this.teamData[this.currentTeam]
      );
    },
    getTeamUrlData(ob) {
      const params = {
        snow_team_id: ob.snow_team_id,
      };
      if (ob.role !== "协作者") {
        getTeamInciteCode(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.teamUrl = res.data.code;
            this.addMemberVisible = true;
          } else {
            // this.$message.error(res.message);
          }
        });
      }
    },
    addMemberClick() {
      this.getTeamUrlData(this.teamData[this.currentTeam]);
    },
    copyTeamUrl() {
      let input = document.createElement("input");
      input.value = this.teamUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy"); //NOSONAR
      document.body.removeChild(input);
      this.$message.success(this.$t("messages.shareCodeCopySuccess"));
    },
    shareProjectUrl() {
      let input = document.createElement("input");
      input.value = this.projectUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy"); //NOSONAR
      document.body.removeChild(input);
      this.$message.success(this.$t("messages.shareCodeCopySuccess"));
    },
    submitJoinTeam() {
      if (this.joinTeamUrl) {
        getTeamInviteConfirm(this.joinTeamUrl).then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.operationSuccess"));
            this.getTeamListData(0, 100);
            this.joinTeamVisible = false;
            this.joinTeamUrl = "";
          }
        });
      }
    },
    submitCessionTeam() {
      let params = {
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        accept_user_id: this.selectTeamMember,
      };
      if (this.selectTeamMember) {
        transferTeam(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.operationSuccess"));
            this.getTeamListData(0, 100);
            this.cessionTeamVisible = false;
          } else {
            // this.$message.error(res.message);
          }
        });
      }
    },
    submitQuitTeam() {
      const params = {
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      quitTeam(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.operationSuccess"));
          window.location.reload();
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    getMemberOfTeamProjectData(start, rows, gns) {
      let params = {
        start: start,
        rows: rows,
        project_gns: gns,
        key_word: this.searchMemberOfTeamProject,
      };
      getMemberOfTeamProject(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.memberTableData = res.data.data;
          this.memberTotal = res.data.total;
          if (this.memberTableData.length > 0) {
            this.getUserRoleTeamProjectData(
              this.teamData[this.currentTeam],
              res.data.user_ids
            );
          }
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    getFileListData(start, rows, gns) {
      let params = {
        category: "文件",
        category_child: "团队文件",
        start: start,
        rows: rows,
        parent_gns: gns,
        keyword: this.fileSearchName,
      };
      searchFile_api(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.fileTableData = res.data.data.map((item) => {
              const ob = { selected: true };
              return Object.assign(item._source, ob);
            });
            this.fileTotal = res.data.total;
          }
        })
        .catch(() => {
          this.fileTableData = [];
        });
    },
    getCellListData(start, rows, gns) {
      let params = {
        category: "CELL",
        category_child: "团队cell",
        start: start,
        rows: rows,
        parent_gns: gns,
        keyword: this.cellSearchName,
        types: null,
      };
      searchFile_api(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.cellTableData = res.data.data.map((item) => {
              const ob = { selected: true };
              return Object.assign(item._source, ob);
            });
            this.cellTotal = res.data.total;
          }
        })
        .catch(() => {
          this.cellTableData = [];
        });
    },
    searchFileName() {
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getFileListData(start, this.filePageSize, this.checkProjectGNS);
    },
    searchCellName() {
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getCellListData(start, this.filePageSize, this.checkProjectGNS);
    },
    memberSizeChange(val) {
      this.memberPageSize = val;
      const start = (this.memberCurrentPage - 1) * this.memberPageSize;
      this.getMemberOfTeamProjectData(
        start,
        this.memberPageSize,
        this.checkProjectGNS
      );
    },
    fileSizeChange(val) {
      this.filePageSize = val;
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getFileListData(start, this.filePageSize, this.checkProjectGNS);
    },
    cellSizeChange(val) {
      this.cellPageSize = val;
      const start = (this.cellCurrentPage - 1) * this.cellPageSize;
      this.getCellListData(start, this.cellPageSize, this.checkProjectGNS);
    },
    memberCurrentChange(val) {
      this.memberCurrentPage = val;
      const start = (this.memberCurrentPage - 1) * this.memberPageSize;
      this.getMemberOfTeamProjectData(
        start,
        this.memberPageSize,
        this.checkProjectGNS
      );
    },
    fileCurrentChange(val) {
      this.fileCurrentPage = val;
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getFileListData(start, this.filePageSize, this.checkProjectGNS);
    },
    cellCurrentChange(val) {
      this.cellCurrentPage = val;
      const start = (this.cellCurrentPage - 1) * this.cellPageSize;
      this.getCellListData(start, this.cellPageSize, this.checkProjectGNS);
    },
    searchMemberOfTeamProjectClick() {
      this.memberCurrentChange = 1;
      const start = (this.memberCurrentChange - 1) * this.pageSize;
      this.getMemberOfTeamProjectData(
        start,
        this.memberPageSize,
        this.checkProjectGNS
      );
    },
    getUserRoleTeamProjectData(ob, user_ids) {
      const params = {
        user_ids: user_ids,
        snow_team_id: ob.snow_team_id,
      };
      this.memberTableLoading = true;
      getUserRoleTeamProject(params).then((res) => {
        if (res.code / 1000 === 200) {
          const tableData = deepClone(this.memberTableData);
          for (let i in tableData) {
            for (let j in res.data.data) {
              if (res.data.data[j].user_id === tableData[i].user_id) {
                tableData[i].user_role = res.data.data[j].user_role;
                tableData[i].is_select = res.data.data[j].is_select;
                tableData[i].user_role_id = res.data.data[j].user_role_id;
              }
            }
          }
          this.memberTableData = tableData.sort((a, b) => {
            return a.user_role_id - b.user_role_id;
          });
        } else {
          // this.$message.error(res.message);
        }
        this.memberTableLoading = false;
      });
    },
    changeCooperaterData() {
      this.cooperaterDataScrollLoadSearch();
      this.addCooperaterVisible = true;
    },
    async cooperaterDataScrollLoad() {
      if (this.cooperaterTableNextPage) {
        const params = {
          project_gns: this.checkProjectGNS,
          snow_team_id: this.teamData[this.currentTeam].snow_team_id,
          start: this.cooperaterTableStart,
          rows: this.cooperaterTableRows,
          display_name: this.searchForAddCooperater,
        };
        this.cooperaterTableLoading = true;
        await getAllCooperater(params).then((res) => {
          if (res.code / 1000 === 200) {
            let newData = res.data.data.map((item) => {
              const ob = { selected: false, note: "" };
              return Object.assign(item, ob);
            });
            newData = this.getTeamViewerNoteApi(newData);
            this.cooperaterTableData.push(...newData);
            this.cooperaterTableNextPage = res.data.is_next_page;
            this.CooperaterSearch = false;
            if (this.cooperaterTableNextPage) {
              this.cooperaterTableStart += this.cooperaterTableRows;
            }
          } else {
            // this.$message.error(res.message);
          }
          this.cooperaterTableLoading = false;
        });
      } else {
        return;
      }
    },
    cooperaterDataScrollLoadSearch() {
      this.cooperaterTableStart = 0;
      this.cooperaterTableData = [];
      this.cooperaterTableNextPage = true;
      this.CooperaterSearch = true;
      this.cooperaterDataScrollLoad();
    },
    cancleSubmitAddCooperater() {
      this.cooperaterTableStart = 0;
      this.cooperaterTableData = [];
      this.cooperaterTableNextPage = true;
      this.CooperaterSearch = true;
      this.searchForAddCooperater = "";
      this.addCooperaterVisible = false;
    },
    submitAddCooperater() {
      const data = [];
      this.cooperaterTableData.forEach((item) => {
        if (item.selected) {
          data.push(item);
        }
      });
      const user_ids = data.map((item) => {
        return item.user_id;
      });
      const params = {
        project_gns: this.checkProjectGNS,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        view_user_ids: user_ids,
      };
      this.AddCooperaterLoading = true;
      if (data.length > 0) {
        addCooperater(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.Success"));
            this.memberCurrentPage = 1;
            this.getMemberOfTeamProjectData(
              0,
              this.memberPageSize,
              this.checkProjectGNS
            );
            this.addCooperaterVisible = false;
            this.resetSelection("cooperaterTable");
          } else {
            // this.$message.error(res.message);
          }
          this.AddCooperaterLoading = false;
        });
      } else {
        this.AddCooperaterLoading = false;
      }
    },
    submitDelMemberOfProject() {
      const data = this.$refs.memberTable.selection;
      if (data.length > 0) {
        const user_ids = data.map((item) => {
          return item.user_id;
        });
        const params = {
          project_gns: this.checkProjectGNS,
          snow_team_id: this.teamData[this.currentTeam].snow_team_id,
          user_ids: user_ids,
        };
        this.delMemberLoading = true;
        delMember(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.deleteSuccess"));
            this.getMemberOfTeamProjectData(
              0,
              this.memberPageSize,
              this.checkProjectGNS
            );
            this.memberTableDatabatchDelete = false;
            this.delMemberOfProjectVisible = false;
            this.quitTeamChecked = false;
            this.resetSelection("memberTable");
          } else {
            // this.$message.error(res.message);
          }
          this.delMemberLoading = false;
        });
        this.delMemberLoading = false;
      }
    },
    submitDelSingleMemberOfproject() {
      const params = {
        project_gns: this.checkProjectGNS,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_ids: [this.waitDeleteMemberOfprojectData.user_id],
      };
      delMember(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.getMemberOfTeamProjectData(
            0,
            this.memberPageSize,
            this.checkProjectGNS
          );
          this.delSingleMemberVisible = false;
          this.dismissTeamChecked = false;
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    deleteSingleMemberOfproject(row) {
      if (!row.is_select) {
        return;
      }
      this.delSingleMemberVisible = true;
      this.waitDeleteMemberOfprojectData = row;
    },
    updateMemberOfProjectPower(type) {
      this.powerType = type;
      this.projectMemberPowerVisible = true;
    },
    cancelUpdateMemberOfProjectPower() {
      this.checkPower = false;
      this.resetSelection("memberTable");
    },
    submitUpdateMemberOfProjectPower() {
      const data = this.$refs.memberTable.selection;
      if (data.length > 0) {
        const user_ids = data.map((item) => {
          return item.user_id;
        });
        const params = {
          project_gns: this.checkProjectGNS,
          snow_team_id: this.teamData[this.currentTeam].snow_team_id,
          user_ids: user_ids,
          permission: this.powerType,
        };
        this.UpdateMemberOfProjectPowerLoading = true;
        updateMemberPower(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.updateSuccess"));
            this.getMemberOfTeamProjectData(
              0,
              this.memberPageSize,
              this.checkProjectGNS
            );
            this.projectMemberPowerVisible = false;
            this.checkPower = false;
            this.resetSelection("memberTable");
          } else {
            // this.$message.error(res.message);
          }
          this.UpdateMemberOfProjectPowerLoading = false;
        });
      }
    },
    searchForProjectName() {
      this.currentPage = 1;
      this.pageSize = 20;
      this.getTeamProjectListData(
        0,
        this.pageSize,
        this.teamData[this.currentTeam]
      );
    },
    getUserRoleData() {
      const snow_team_id = this.teamData[this.currentTeam].snow_team_id;
      getUserRole(snow_team_id).then((res) => {
        if (res.code / 1000 === 200) {
          this.currentUserRoleType = res.data.user_role;
          this.currentUserRoleId = res.data.user_role_id;
          this.currentUserId = res.data.user_id;
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    batchCopyProjectApi(project_list, snow_team_id) {
      const params = {
        project_gns_list: project_list,
        snow_team_id: snow_team_id,
        type: "team",
      };
      this.copyLoading = true;
      batchCopyProject(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.copySuccess"));
            this.copyToTeamVisible = false;
            this.selectCopy = false;
            this.copyLoading = false;
          } else {
            // this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.copyLoading = false;
        });
    },
    batchCopyProjectToPersonApi(project_list, snow_team_id) {
      const params = {
        project_gns_list: project_list,
        snow_team_id: snow_team_id,
      };
      this.copyLoading = true;
      batchCopyProjectToPerson(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.copySuccess"));
            this.copyToTeamVisible = false;
            this.selectCopy = false;
            this.copyLoading = false;
          } else {
            // this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.copyLoading = false;
        });
    },
    batchCopyFileApi(project_list, snow_team_id) {
      const params = {
        file_snow_ids: project_list,
        target_project_gns: snow_team_id,
        copy_type: "team",
      };
      this.copyFileLoading = true;
      copyFiles(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.copySuccess"));
            this.copyToTeamProjectVisible = false;
            this.selectCopy = false;
          } else {
            // this.$message.error(res.message);
          }
          this.copyFileLoading = false;
        })
        .catch(() => {
          this.copyFileLoading = false;
        });
    },
    batchCopyFileToPersonApi(project_list, snow_team_id) {
      const params = {
        file_snow_ids: project_list,
        target_project_gns: snow_team_id,
        copy_type: "user",
      };
      this.copyFileLoading = true;
      copyFiles(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.$message.success(this.$t("messages.copySuccess"));
            this.copyToTeamProjectVisible = false;
            this.selectCopy = false;
          } else {
            // this.$message.error(res.message);
          }
          this.copyFileLoading = false;
        })
        .catch(() => {
          this.copyFileLoading = false;
        });
    },
    toCopy() {
      this.selectTeamList = this.$refs.projectTable.selection;
      if (this.selectTeamList.length > 0) {
        this.copyToTeamVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileCopy() {
      this.selectTeamList = this.$refs.fileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.copyToTeamProjectVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_9"));
      }
    },
    async submitCopy() {
      if (this.selectCopyTeam === "personal") {
        const snow_team_id = this.teamData[this.currentTeam].snow_team_id;
        // const select_project_gns = this.selectTeamList.map((item) => {
        //   return item.gns;
        // });
        // this.batchCopyProjectToPersonApi(select_project_gns, snow_team_id);
        this.copyLoading = true;
        let res = await batchProjectCopyTo(this.selectTeamList, "", 1, 2);
        if (res) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamVisible = false;
          this.selectCopy = false;
          this.copyLoading = false;
        } else {
          this.copyLoading = false;
          this.$message.error(this.$t("messages.operationFailed"));
        }
      } else {
        const snow_team_id = this.selectCopyTeam;
        this.copyLoading = true;
        let res = await batchProjectCopyTo(
          this.selectTeamList,
          snow_team_id,
          2,
          2
        );
        if (res) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamVisible = false;
          this.selectCopy = false;
          this.copyLoading = false;
        } else {
          this.copyLoading = false;
          this.$message.error(this.$t("messages.operationFailed"));
        }
        // const select_project_gns = this.selectTeamList.map((item) => {
        //   return item.gns;
        // });
        // this.batchCopyProjectApi(select_project_gns, snow_team_id);
      }
    },
    async submitFileCopy() {
      this.copyFileLoading = true;
      if (this.selectCopyTeam[0] === "personal") {
        const snow_team_gns = this.selectCopyTeam[1];

        let res = await batchFileCopyTo(
          this.selectTeamList,
          snow_team_gns,
          "",
          1,
          2
        );
        if (res) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamProjectVisible = false;
          this.selectCopy = false;
        } else {
          this.$message.error(this.$t("messages.operationFailed"));
        }
        this.copyFileLoading = false;
      } else {
        const snow_team_gns = this.selectCopyTeam[1];
        const team_snow_id = this.selectCopyTeam[0];

        let res = await batchFileCopyTo(
          this.selectTeamList,
          snow_team_gns,
          team_snow_id,
          2,
          2
        );
        if (res) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamProjectVisible = false;
          this.selectCopy = false;
        } else {
          this.$message.error(res.message);
        }
        this.copyFileLoading = false;
      }
    },
    getProjectShareCode() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        snow_project_gns_list: select_project_gns,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        share_type: "team",
      };
      getShareTeamProjectCode(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectUrl = res.data.code;
          this.shareProjectCodeVisible = true;
          this.selectShare = false;
          this.resetSelection("projectTable");
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    getFileShareCode() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        snow_project_gns_list: select_project_gns,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        share_type: "team",
      };
      getShareTeamProjectCode(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectUrl = res.data.code;
          this.shareProjectCodeVisible = true;
          this.selectShare = false;
          this.resetSelection("projectTable");
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    toShare() {
      this.selectTeamList = this.$refs.projectTable.selection;
      if (this.selectTeamList.length > 0) {
        this.getProjectShareCode();
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileShare() {
      this.selectTeamList = this.$refs.fileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.getFileShareCode();
      } else {
        this.$message.error(this.$t("messages.error_9"));
      }
    },
    submitUpdateInfo() {
      const params = {
        team_gns: this.teamData[this.currentTeam].gns,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        team_name: this.updateTeamName,
      };
      updateTeamName(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.updateTeamName = "";
          this.teamName = "";
          this.updateInfoVisible = false;
          this.getTeamListData(0, 100);
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    getProjectPermissionsData() {
      const all_project_gns = this.projectTableData.map((item) => {
        return item.gns;
      });
      const params = {
        project_gns_list: all_project_gns,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.loading = true;
      getProjectPermissions(params).then((res) => {
        if (res.code / 1000 === 200) {
          res.data.forEach((item) => {
            for (let i in this.projectTableData) {
              if (this.projectTableData[i].gns === item.gns) {
                if (item.permission_id < 3) {
                  this.projectTableData[i].is_permission = true;
                  this.projectTableData[i].can_delete = true;
                }
              }
            }
          });
        } else {
          // this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    deleteProjectApi() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        project_gns_list: select_project_gns,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        delete_type: "team",
      };
      this.deleteLoading = true;
      deleteProject(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let j in select_project_gns) {
            for (let i in this.projectTableData) {
              if (select_project_gns[j] === this.projectTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.projectTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.projectTableData.length == 0) {
            this.getTeamProjectListData(
              0,
              this.pageSize,
              this.teamData[this.currentTeam]
            );
          }
          this.deleteProjectVisible = false;
          this.resetSelection("projectTable");
        } else {
          // this.$message.error(res.message);
        }
        this.deleteLoading = false;
      });
    },
    deleteFileApi() {
      const select_file_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        gns: select_file_gns,
        category: "文件",
      };
      this.deleteLoading = true;
      deleteFiles(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let j in select_file_gns) {
            for (let i in this.fileTableData) {
              if (select_file_gns[j] === this.fileTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.fileTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.fileTableData.length == 0) {
            this.getFileListData(0, this.filePageSize, this.checkProjectGNS);
          }
          this.deleteFileVisible = false;
          this.deleteLoading = false;
          this.resetSelection("fileTable");
        } else {
          // this.$message.error(res.message);
        }
        this.deleteLoading = false;
      });
    },
    deleteCellApi() {
      const select_cell_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        gns: select_cell_gns,
        category: "CELL",
      };
      this.deleteLoading = true;
      deleteFiles(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let j in select_cell_gns) {
            for (let i in this.cellTableData) {
              if (select_cell_gns[j] === this.cellTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.cellTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.cellTableData.length == 0) {
            this.getCellListData(0, this.cellPageSize, this.checkProjectGNS);
          }
          this.deleteCellVisible = false;
          this.resetSelection("cellTable");
        } else {
          // this.$message.error(res.message);
        }
        this.deleteLoading = false;
      });
    },
    toDelete() {
      this.selectTeamList = this.$refs.projectTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteProjectVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileDelete() {
      this.selectTeamList = this.$refs.fileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteFileVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_9"));
      }
    },
    toCellDelete() {
      this.selectTeamList = this.$refs.cellTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteCellVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_9"));
      }
    },
    submitDelete() {
      this.deleteProjectApi();
      this.dismissDeleteChecked = false;
    },
    submitDeleteFile() {
      this.deleteFileApi();
      this.dismissDeleteChecked = false;
    },
    submitDeleteCell() {
      this.deleteCellApi();
      this.dismissDeleteChecked = false;
    },
    setTeamPower(type) {
      this.setRoleType = type;
      this.selectMemberList = this.$refs.projectMemberTable.selection;
      if (this.selectMemberList.length > 0) {
        this.setTeamRoleVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_2"));
      }
    },
    submitSetTeamRole() {
      const user_ids = this.selectMemberList.map((item) => {
        return item.user_id;
      });
      let params_user_ids = [];
      let user_now_role_like = true;
      user_ids.forEach((user_id) => {
        this.selectMemberList.forEach((item) => {
          if (item.user_id == user_id) {
            if (item.user_role !== this.setRoleType) {
              user_now_role_like = false;
              params_user_ids.push(user_id);
            }
          }
        });
      });
      if (user_now_role_like) {
        this.$message.error(
          `${this.$t("messages.powerTips_27")}“${this.transRoleType(
            this.setRoleType
          )}”`
        );
        return;
      }
      this.teamRoleLoading = true;
      const params = {
        user_ids: params_user_ids,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_role: this.alluserRoleInfo[this.setRoleType],
      };
      batchTeamRole(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.setTeamRoleVisible = false;
          this.selectTeamPower = false;
          this.start = 0;
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
          this.resetSelection("projectMemberTable");
        } else {
          // this.$message.error(res.message);
        }
        this.teamRoleLoading = false;
      });
    },
    toDeleteMember() {
      this.selectMemberList = this.$refs.projectMemberTable.selection;
      if (this.selectMemberList.length > 0) {
        this.deleteMemberVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_2"));
      }
    },
    async getAllMemberOfTeamDataScroll(start, rows, ob) {
      if (!this.AllMemberNextPage) {
        return;
      }
      let params = {
        start: start,
        rows: rows,
        snow_team_id: ob.snow_team_id,
        team_gns: ob.gns,
        key_word: this.searchForProjectMember,
      };
      this.AllMemberLoading = true;
      await getTeamViewer(params).then((res) => {
        if (res.code / 1000 === 200) {
          let newData = res.data.data.map((item) => {
            const ob = { selected: false, note: "" };
            return Object.assign(item, ob);
          });
          newData = this.getTeamViewerNoteApi(newData);
          this.powerProjectMembertableData.push(...newData);
          this.AllMemberNextPage = res.data.is_next_page;
          this.AllMemberSearch = false;
          if (res.data.is_next_page) {
            this.scrollStart_1 = res.data.next;
          }
        } else {
          // this.$message.error(res.message);
        }
        this.AllMemberLoading = false;
      });
    },
    getTeamViewerNoteApi(newData) {
      const user_ids = newData.map((item) => {
        return item.user_id;
      });
      const params = {
        user_ids: user_ids,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.AllMemberLoading = true;
      this.cooperaterTableLoading = true;
      let reData = deepClone(newData);
      getTeamViewerNote(params).then((res) => {
        if (res.code / 1000 == 200) {
          res.data.data.forEach((v) => {
            reData.forEach((item) => {
              if (v.user_id == item.user_id) {
                item.note = v.note;
              }
            });
          });
        }
      });
      return reData;
    },
    async getTeamProjectListDataScroll(start, rows, ob) {
      let params = {
        start: start,
        rows: rows,
        snow_team_id: ob.snow_team_id,
        team_gns: ob.gns,
      };
      this.TeamProjectLoading = true;
      getTeamProjectList(params).then((res) => {
        if (res.code / 1000 === 200) {
          const newData = res.data.data.map((item) => {
            const ob = { selected: false, show: false };
            const pe = { no_permission: false };
            const child = { children: [] };
            return Object.assign(item._source, ob, pe, child);
          });
          this.powerProjectProtableData.push(...newData);
          this.TeamProjectNextPage = res.data.is_next;
          if (res.data.is_next) {
            this.scrollStart_2 = res.data.next;
          }
        }
      });
      this.TeamProjectLoading = false;
    },
    toDeleteSingleMember(row) {
      this.deleteSingleMemberVisible = true;
      this.setDeleteMember = row;
    },
    submitDeleteSingleMember() {
      const params = {
        user_ids: [this.setDeleteMember.user_id],
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.deleteMemberLoading = true;
      batchDeleteMember(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.deleteSingleMemberVisible = false;
          for (let i in this.projectMemberTableData) {
            if (
              this.projectMemberTableData[i].user_id ==
              this.setDeleteMember.user_id
            ) {
              this.projectMemberTableData.splice(i, 1);
            }
          }
          this.deleteSingleMemberChecked = false;
        } else {
          // this.$message.error(res.message);
        }
        this.deleteMemberLoading = false;
      });
    },
    submitDeleteMember() {
      const user_ids = this.selectMemberList.map((item) => {
        return item.user_id;
      });
      const params = {
        user_ids: user_ids,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.deleteMemberLoading = true;
      batchDeleteMember(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDeleteMember = false;
          this.deleteMemberVisible = false;
          this.deleteMemberChecked = false;
          this.resetSelection("projectMemberTable");
        } else {
          // this.$message.error(res.message);
        }
        this.deleteMemberLoading = false;
      });
    },
    toUpdateProjectPower() {
      this.batchProjectPowerVisible = true;
      this.scrollStart_1 = 0;
      this.scrollStart_2 = 0;
      this.powerProjectMembertableData = [];
      this.powerProjectProtableData = [];
      this.AllMemberNextPage = true;
      this.TeamProjectNextPage = true;
      this.AllMemberSearch = true;
      this.TeamProjectSearch = true;
      this.getAllMemberOfTeamDataScroll(
        this.scrollStart_1,
        this.scrollRows_1,
        this.teamData[this.currentTeam]
      );
      this.getTeamProjectListDataScroll(
        this.scrollStart_2,
        this.scrollRows_2,
        this.teamData[this.currentTeam]
      );
    },
    async AllMemberOfTeamDataScrollLoad() {
      if (this.AllMemberNextPage) {
        await this.getAllMemberOfTeamDataScroll(
          this.scrollStart_1,
          this.scrollRows_1,
          this.teamData[this.currentTeam]
        );
      }
    },
    async AllMemberOfTeamDataScrollLoadSearch() {
      this.scrollStart_1 = 0;
      this.powerProjectMembertableData = [];
      this.AllMemberNextPage = true;
      this.AllMemberSearch = true;
      await this.getAllMemberOfTeamDataScroll(
        this.scrollStart_1,
        this.scrollRows_1,
        this.teamData[this.currentTeam]
      );
    },
    async TeamProjectListDataScrollLoad() {
      if (this.TeamProjectNextPage) {
        await this.getTeamProjectListDataScroll(
          this.scrollStart_2,
          this.scrollRows_2,
          this.teamData[this.currentTeam]
        );
      }
    },
    async TeamProjectListDataScrollLoadSearch() {
      this.scrollStart_2 = 0;
      this.powerProjectProtableData = [];
      this.TeamProjectNextPage = true;
      this.TeamProjectSearch = true;
      await this.getTeamProjectListDataScroll(
        this.scrollStart_2,
        this.scrollRows_2,
        this.teamData[this.currentTeam]
      );
    },
    updateProjectPower(type) {
      this.setPowerType = type;
    },
    cancleUpdateProjectPower() {
      this.batchProjectPowerVisible = false;
      this.setPowerType = null;
      this.scrollStart_1 = 0;
      this.powerProjectMembertableData = [];
      this.AllMemberNextPage = true;
      this.AllMemberSearch = true;
      this.scrollStart_2 = 0;
      this.powerProjectProtableData = [];
      this.TeamProjectNextPage = true;
      this.TeamProjectSearch = true;
      this.searchForProjectPro = "";
      this.searchForProjectMember = "";
      this.powerDataSet = { project_list: [], file_list: [], cell_list: [] };
    },
    submitUpdateProjectPower(is_sure) {
      function arrDelete(arr, element) {
        let new_set = new Set(arr);
        new_set.delete(element);
        return [...new_set];
      }
      let user_ids = [];
      this.powerProjectMembertableData.forEach((item) => {
        if (item.selected) {
          user_ids.push(item.user_id);
        }
      });
      if (
        user_ids.length == 0 ||
        (this.powerDataSet.project_list.length == 0 &&
          this.powerDataSet.file_list.length == 0 &&
          this.powerDataSet.cell_list.length == 0)
      ) {
        this.$message.error(this.$t("messages.error_3"));
        return;
      }
      const params = {
        user_ids: user_ids,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        permission: this.setPowerType,
        project_list: arrDelete(this.powerDataSet.project_list),
        file_list: arrDelete(this.powerDataSet.file_list),
        cell_list: arrDelete(this.powerDataSet.cell_list),
      };
      this.projectPowerLoading = true;
      batchProjectPermission(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.getMemberOfTeamData(
              0,
              this.pageSize,
              this.teamData[this.currentTeam]
            );
            this.$message.success(this.$t("messages.Success"));
            if (is_sure) {
              this.cancleUpdateProjectPower();
            }
          } else {
            // this.$message.error(res.message);
          }
          this.projectPowerLoading = false;
        })
        .catch((err) => {
          this.projectPowerLoading = false;
        });
    },
    userRoleSelectChange(row) {
      const params = {
        user_ids: [row.user_id],
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_role: this.alluserRoleInfo[row.user_role],
      };
      this.selectRoleLoading = true;
      batchTeamRole(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
        } else {
          // this.$message.error(res.message);
          row.user_role = "协作者";
        }
        this.selectRoleLoading = false;
      });
    },
    SetDevicePower(type) {
      this.selectMemberList = this.$refs.projectMemberTable.selection;
      if (this.selectMemberList.length > 0) {
        this.devicePowerType = type;
        this.projectMemberDevicePowerVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_2"));
      }
    },
    submitUpdateMemberDevicetPower() {
      const user_ids = this.selectMemberList.map((item) => {
        return item.user_id;
      });
      const params = {
        user_ids: user_ids,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_component_permission:
          this.allDevicePowerInfo[this.devicePowerType],
      };
      this.UpdateMemberDevicetPowerLoading = true;
      batchDevicePower(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.selectDevicePower = false;
          this.projectMemberDevicePowerVisible = false;
          this.start = 0;
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
          this.resetSelection("projectMemberTable");
        } else {
          // this.$message.error(res.message);
        }
        this.UpdateMemberDevicetPowerLoading = false;
      });
    },
    updateSingleDevcicePower(row) {
      this.singleDevicePowerVisible = true;
      this.devicePower = this.allDevicePowerInfo[row.user_component_permission];
      this.setRow = row;
    },
    submitSingleDevicetPower() {
      const params = {
        user_ids: [this.setRow.user_id],
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_component_permission: this.devicePower,
      };
      this.DevicetPowerLoading = true;
      batchDevicePower(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.singleDevicePowerVisible = false;
          this.start = 0;
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
        } else {
          // this.$message.error(res.message);
        }
        this.DevicetPowerLoading = false;
      });
    },
    toEditMember(row) {
      this.setRow = deepClone(row);
      this.memberEditVisible = true;
      if (row.user_role == "所有者" || row.user_role == "管理者") {
        this.cantSelectDevicePower = true;
      } else {
        this.cantSelectDevicePower = false;
      }
    },
    editSelectChange(row) {
      if (row.user_role == "管理者") {
        this.cantSelectDevicePower = true;
        row.user_component_permission = "可编辑";
      } else {
        this.cantSelectDevicePower = false;
      }
    },
    submitMemberEdit() {
      const params = {
        note: this.setRow.note,
        snow_team_id: this.teamData[this.currentTeam].snow_team_id,
        user_role: this.alluserRoleInfo[this.setRow.user_role],
        update_user_id: this.setRow.user_id,
        user_component_permission:
          typeof this.setRow.user_component_permission === "number"
            ? this.setRow.user_component_permission
            : this.allDevicePowerInfo[this.setRow.user_component_permission],
      };
      this.MemberEditLoading = true;
      updateMemberEdit(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.updateSuccess"));
          this.memberEditVisible = false;
          this.start = 0;
          this.getMemberOfTeamData(
            0,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
        } else {
          // this.$message.error(res.message);
        }
        this.MemberEditLoading = false;
      });
    },
    async getDeviceListData() {
      const params = {
        start: (this.currentPage - 1) * this.pageSize,
        rows: this.pageSize,
        key_words: this.searchDevice,
        team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.clTableLoading = true;
      let res = await getTeamDeviceApi(params);
      if (!res) {
        this.clTableLoading = false;
        return;
      }
      this.total = res.total;
      this.clTableData = res.data.map((item) => {
        return item._source;
      });
      this.clTableLoading = false;
    },
    async searchDeviceClick() {
      this.currentPage = 1;
      this.getDeviceListData();
    },
    submitUploadDevice(row) {
      const params = {
        component_type: 2,
        snow_id: this.deviceForm.snow_id,
        name: this.deviceForm.name,
        notes: this.deviceForm.note,
        label_list: this.deviceForm.label,
      };
      uploadDevice(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.uploadSuccess"));
          this.currentPage = 1;
          this.getDeviceListData();
          this.uploadDeviceVisible = false;
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    submitUploadSymbol() {
      const params = {
        symbol_group: 2,
        snow_id: this.deviceForm.snow_id,
        name: this.deviceForm.name,
        notes: this.deviceForm.note,
        label_list: this.deviceForm.label,
      };
      uploadSymbol(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.uploadSuccess"));
          this.currentPage = 1;
          this.getTeamSymbolData(
            (this.currentPage - 1) * this.pageSize,
            this.pageSize,
            this.teamData[this.currentTeam]
          );
          this.uploadSymbolVisible = false;
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    toDeleteDevice() {
      this.selectDeviceList = this.$refs.clTable.selection;
      if (this.selectDeviceList.length > 0) {
        this.delDeviceVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    },
    toDeleteStmbol() {
      this.selectDeviceList = this.$refs.symbolTable.selection;
      if (this.selectDeviceList.length > 0) {
        this.delSymbolVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    },
    toExportDevice: _.debounce(async function () {
      this.selectDeviceList = this.$refs.clTable.selection;
      if (this.selectDeviceList.length > 0) {
        for (let i in this.selectDeviceList) {
          let file_data_u8 = await getObjectDataFn(
            this.selectDeviceList[i].file_url
          );
          downloadFileData(file_data_u8, this.selectDeviceList[i].name, "gds");
        }
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    }, 500),
    submitDelDevice() {
      const device_snow_ids = this.selectDeviceList.map((item) => {
        return item.snow_id;
      });
      const params = {
        component_type: 2,
        snow_id: device_snow_ids,
        team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.delDeviceLoading = true;
      deleteDevice(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.delDeviceVisible = false;
          this.deleteDeviceChecked = false;
          this.selectDeviceDelete = false;
          let deleteIndex = [];
          for (let j in device_snow_ids) {
            for (let i in this.clTableData) {
              if (device_snow_ids[j] === this.clTableData[i].snow_id) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.clTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.clTableData.length == 0) {
            this.currentPage = 1;
            this.getDeviceListData();
          }
          this.resetSelection("clTable");
        } else {
          // this.$message.error(res.message);
        }
        this.delDeviceLoading = false;
      });
    },
    submitDelSymbol() {
      const device_snow_ids = this.selectDeviceList.map((item) => {
        return item.snow_id;
      });
      const params = {
        symbol_group: 2,
        snow_ids: device_snow_ids,
        team_id: this.teamData[this.currentTeam].snow_team_id,
      };
      this.delSymbolLoading = true;
      deleteSymbol(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.delSymbolVisible = false;
          this.selectDeviceDelete = false;
          let deleteIndex = [];
          for (let j in device_snow_ids) {
            for (let i in this.symbolTableData) {
              if (device_snow_ids[j] === this.symbolTableData[i].snow_id) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.symbolTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.symbolTableData.length == 0) {
            this.currentPage = 1;
            this.getTeamSymbolData(
              (this.currentPage - 1) * this.pageSize,
              this.pageSize,
              this.teamData[this.currentTeam]
            );
          }
          this.resetSelection("symbolTable");
        } else {
          // this.$message.error(res.message);
        }
        this.delSymbolLoading = false;
      });
    },
    toUploadDevice(row) {
      this.deviceForm = {
        name: row.name,
        label: row.label,
        note: row.note,
        snow_id: row.snow_id,
      };
      this.uploadDeviceVisible = true;
    },
    toUploadSymbol(row) {
      this.deviceForm = {
        name: row.name,
        label: row.label,
        note: row.note,
        snow_id: row.snow_id,
      };
      this.uploadSymbolVisible = true;
    },
    submitUpdateDevice(row) {
      this.submitUploadDevice(row);
    },
    checkProjectFile(row) {
      if (row.children.length > 0) {
        row.show = !row.show;
        return;
      }
      let params = {
        start: 0,
        rows: 100,
        snow_team_id: row.snow_team_id,
        team_gns: row.gns,
      };
      this.file_loading = true;
      getTeamProjectList(params).then((res) => {
        if (res.code / 1000 === 200) {
          const newData = res.data.data.map((item) => {
            const ob = { selected: false, show: false };
            const pe = { no_permission: false };
            const child = { children: [] };
            return Object.assign(item._source, ob, pe, child);
          });
          row.children.push(...newData);
        }
      });
      row.show = !row.show;
      this.file_loading = false;
    },
    getDialogFileListData: _.debounce(function (params, row) {
      getFileList_api(params).then((res) => {
        if (res.code / 1000 === 200) {
          const newData = res.data.data.map((item) => {
            const ob = { selected: false, show: false };
            const pe = { no_permission: false };
            const child = { children: [] };
            return Object.assign(item._source, ob, pe, child);
          });
          row.children.push(...newData);
        }
        this.cell_loading = false;
      });
    }, 500),
    checkProjectCell(row) {
      if (row.children.length > 0) {
        row.show = !row.show;
        return;
      }
      let params = {
        category: "文件",
        category_child: "团队文件",
        start: 0,
        rows: 200,
        parent_gns: row.gns,
      };
      this.cell_loading = true;
      this.getDialogFileListData(params, row);
      row.show = !row.show;
      this.cell_loading = false;
    },
    getDialogCellListData: _.debounce(function (params, row) {
      getCellList_api(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            const newData = res.data.data.map((item) => {
              const ob = { selected: false, show: false };
              const pe = { no_permission: false };
              const child = { children: [] };
              return Object.assign(item._source, ob, pe, child);
            });
            row.children.push(...newData);
            this.cell_cell_loading = false;
          }
        })
        .catch((err) => {
          this.cell_cell_loading = false;
        });
    }, 500),
    checkProjectCellCell(row) {
      if (row.children.length > 0) {
        row.show = !row.show;
        return;
      }
      let params = {
        category: "CELL",
        category_child: "团队cell",
        start: 0,
        rows: 200,
        parent_gns: row.gns,
      };
      this.cell_cell_loading = true;
      this.getDialogCellListData(params, row);
      row.show = !row.show;
      this.cell_cell_loading = false;
    },
    cellCellSelectedChange(row, file_type) {
      function arrDelete(arr, element) {
        let new_set = new Set(arr);
        new_set.delete(element);
        return [...new_set];
      }
      if (file_type == 1) {
        if (row.selected) {
          this.powerDataSet.project_list.push(row.gns);
        } else {
          this.powerDataSet.project_list = arrDelete(
            this.powerDataSet.project_list,
            row.gns
          );
        }
      }
      if (file_type == 2) {
        if (row.selected) {
          this.powerDataSet.file_list.push(row.gns);
        } else {
          this.powerDataSet.file_list = arrDelete(
            this.powerDataSet.file_list,
            row.gns
          );
        }
      }
      if (file_type == 3) {
        if (row.selected) {
          this.powerDataSet.cell_list.push(row.gns);
        } else {
          this.powerDataSet.cell_list = arrDelete(
            this.powerDataSet.cell_list,
            row.gns
          );
        }
      }
    },
    async getUserProjectData(node, data) {
      if (node.level > 1) return;
      let res = await getUserProjectList_api({
        start: 0,
        rows: 50,
      });
      if (res.code == 200000) {
        data.children = res.data.data.map((item) => {
          return { label: item._source.name, value: item._source.gns };
        });
      }
    },
    async getTeamProjectData(node, data) {
      if (node.level > 1) return;
      let res = await getTeamProjectList_api({
        snow_team_id: data.snow_id,
        start: 0,
        rows: 50,
        key_words: "",
        team_gns: data.gns,
      });
      if (res.code == 200000) {
        data.children = res.data.data.map((item) => {
          return { label: item._source.name, value: item._source.gns };
        });
      }
    },
    async getAllProjectSelectOptions() {
      let all_data = [
        {
          value: null,
          label: this.$store.state.username,
          children: [],
        },
      ];
      let personal_res = await getUserProjectList_api({
        start: 0,
        rows: 50,
      });
      if (personal_res.code == 200000) {
        all_data[0].children = personal_res.data.data.map((item) => {
          return {
            value: item._source.snow_id,
            label: item._source.name,
          };
        });
      }
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        for (let i = 0; i < team_objs.length; i++) {
          if (
            team_objs[i].library_permission !== 1 &&
            team_objs[i].snow_team_id !==
              this.teamData[this.currentTeam].snow_team_id
          ) {
            let team_obj = {
              value: team_objs[i].snow_team_id,
              label: team_objs[i].team_name,
              gns: team_objs[i].gns,
              snow_id: team_objs[i].snow_team_id,
              children: [],
            };
            all_data.push(team_obj);
            this.teamOptionList.push(team_obj);
          }
        }
      }
      return all_data;
    },
    async toSearchComponent() {
      this.searchComponentLoading = true;
      let res = null;
      if (this.search_library_type == 0) {
        if (!this.search_library_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          library_snow_id: this.search_library_id,
        };
        res = await getPersonalLibraryComponent(params);
      }
      if (this.search_library_type == 1) {
        if (!this.search_team_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          team_id: this.search_team_id,
        };
        res = await getTeamLibraryComponent(params);
      } else if (this.search_library_type == 2) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          search_type: 2,
        };
        res = await getPublicLibraryComponent(params);
      } else if (this.search_library_type == 3) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
        };
        res = await getOfficialLibraryComponent(params);
      } else if (this.search_library_type == 4) {
        if (!this.search_project_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          team_id: this.search_team_id,
          project_snow_id: this.search_project_id,
        };
        res = await getPDKLibraryComponent(params);
      }
      if (res.code == 200000) {
        this.searchComponentTableData = res.data.data.map((item) => {
          return item._source;
        });
      }
      this.searchComponentLoading = false;
    },
    async toSearchSymbol() {
      this.searchSymbolLoading = true;
      let res = null;
      if (this.search_library_type == 0) {
        if (!this.search_library_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          library_snow_id: this.search_library_id,
        };
        res = await getPersonalLibrarySymbol(params);
      }
      if (this.search_library_type == 1) {
        if (!this.search_team_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          team_id: this.search_team_id,
        };
        res = await getTeamLibrarySymbol(params);
      } else if (this.search_library_type == 2) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          search_type: 2,
        };
        res = await getPublicLibrarySymbol(params);
      } else if (this.search_library_type == 3) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
        };
        res = await getOfficialLibrarySymbol(params);
      } else if (this.search_library_type == 4) {
        if (!this.search_project_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          team_id: this.search_team_id,
          project_snow_id: this.search_project_id,
        };
        res = await getPDKLibrarySymbol(params);
      }
      if (res.code == 200000) {
        this.searchSymbolTableData = res.data.data;
      }
      this.searchSymbolLoading = false;
    },
    async getLibraryInfo() {
      this.libraryList = [];
      let res = null;
      if (this.clFocus) {
        res = await getLibraryList();
      } else {
        res = await getSymbolList();
      }
      if (res.code == 200000) {
        this.libraryList = res.data.data.map((item) => {
          const ob = { checked: false };
          return Object.assign(item, ob);
        });
        this.libraryOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
        this.select_component_obj = this.libraryList[0];
        this.select_component_index = 0;
      }
    },
    submitBatchAddComponent: _.debounce(async function (status, cover) {
      if (this.$refs.searchComponentTable.selection.length == 0) {
        this.$message.error(this.$t("messages.selectNone"));
        return;
      }
      let all_snow_ids = this.$refs.searchComponentTable.selection.map(
        (item) => {
          return item.snow_id;
        }
      );
      let team_data = {
        team_snow_id: this.search_team_id,
        snow_data: all_snow_ids,
      };
      if (all_snow_ids.length == 0) return;
      this.replaceLoading = true;
      let params = {
        snow_data: {
          personal: this.search_library_type == 0 ? all_snow_ids : [],
          team: this.search_library_type == 1 ? team_data : {},
          public: this.search_library_type == 2 ? all_snow_ids : [],
          official: this.search_library_type == 3 ? all_snow_ids : [],
          pdk: this.search_library_type == 4 ? all_snow_ids : [],
        },
        team_snow_id: this.teamData[this.currentTeam].snow_team_id,
        is_cover: cover,
      };
      try {
        let res = await batchAddTeamComponent(params);
        if (res.code == 200000) {
          if (
            res.data.data.name_exists &&
            res.data.data.name_exists.length > 0
          ) {
            this.existsCellList = res.data.data.name_exists;
            this.existsCellNameVisible = true;
          } else {
            this.cancelSubmitReplaceCellName();
            this.$refs.searchComponentTable.clearSelection();
            this.$message.success(this.$t("messages.Success"));
            setTimeout(() => {
              this.getDeviceListData();
            }, 500);
          }
        }
      } catch {
        this.cancelSubmitReplaceCellName();
        this.$refs.searchComponentTable.clearSelection();
        this.replaceLoading = false;
      }
      if (status) {
        this.cancelSubmitAdd();
      }
      this.replaceLoading = false;
    }, 500),
    submitBatchAddSymbol: _.debounce(async function (status, cover) {
      if (this.$refs.searchSymbolTable.selection.length == 0) {
        this.$message.error(this.$t("messages.selectNone"));
        return;
      }
      let all_snow_ids = this.$refs.searchSymbolTable.selection.map((item) => {
        return item.snow_id;
      });
      let team_data = {
        team_snow_id: this.search_team_id,
        snow_data: all_snow_ids,
      };

      if (all_snow_ids.length == 0) return;
      this.replaceLoading = true;
      let params = {
        snow_data: {
          personal: this.search_library_type == 0 ? all_snow_ids : [],
          team: this.search_library_type == 1 ? team_data : {},
          public: this.search_library_type == 2 ? all_snow_ids : [],
          official: this.search_library_type == 3 ? all_snow_ids : [],
          pdk: this.search_library_type == 4 ? all_snow_ids : [],
        },
        team_snow_id: this.teamData[this.currentTeam].snow_team_id,
        is_cover: cover,
      };
      try {
        let res = await batchAddTeamSymbol(params);
        if (res.code == 200000) {
          if (
            res.data.data.name_exists &&
            res.data.data.name_exists.length > 0
          ) {
            this.existsCellList = res.data.data.name_exists;
            this.existsSymbolNameVisible = true;
          } else {
            this.cancelSubmitReplaceCellName();
            this.$refs.searchSymbolTable.clearSelection();
            this.$message.success(this.$t("messages.Success"));
            setTimeout(() => {
              this.getTeamSymbolData();
            }, 500);
          }
        }
      } catch {
        this.cancelSubmitReplaceCellName();
        this.$refs.searchSymbolTable.clearSelection();
        this.replaceLoading = false;
      }
      if (status) {
        this.cancelSubmitAdd();
      }
      this.replaceLoading = false;
    }, 500),
    cancelSubmitReplaceCellName() {
      this.existsCellList = [];
      this.existsCellNameVisible = false;
      this.existsSymbolNameVisible = false;
    },
    cancelSubmitAdd() {
      this.searchComponentName = "";
      this.search_component_type = 1;
      this.search_project_id = "";
      this.search_library_type = 0;
      this.search_library_id = "";
      this.search_team_id = "";
      this.searchComponentTableData = [];
      this.searchSymbolTableData = [];
      this.batchAddSymbolVisible = false;
      this.batchAddComponentVisible = false;
    },
  },
  mounted() {
    bus.$on("errDialog", (data) => {
      this.errDialog(this.$t("messages.wrong"), data, true);
    });
  },
  created() {
    this.getTeamListData(0, 100);
  },
};
</script>
<style lang="less" scoped>
.info {
  display: flex;
}
.noTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  .noTeam-svg {
    width: auto;
    height: 190px;
  }
  .noTeam-text {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
  }
}
/deep/ .el-breadcrumb__inner:hover {
  cursor: pointer;
  color: #378ddd;
}
.t-item1 {
  width: 240px;
  height: 100%;
  margin: 12px;
  background: #fff;
}
.t-item1__title {
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #ddd;
}
.t-item1__body {
  height: 100vh;
  button {
    display: inline-block;
  }
  .btn-icon {
    height: 20px;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
  .team-name {
    width: calc(100% - 40px);
  }
  .team-icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    margin: 5px;
  }
  .role-icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    margin: 5px;
  }
  .teamInfo {
    width: calc(100% - 14px);
    display: flex;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    padding-left: 10px;
    div {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
    }
  }
  .teamInfo:hover {
    background: #dce6f8;
  }
  .active {
    background: #dce6f8;
    border-right: solid 4px #0c7de6;
  }
}
.fill {
  background: #4385f2;
  border: 1px solid #4385f2;
  color: #fff;
}
.default {
  border: 1px solid #378ddd;
  color: #378ddd;
  background: transparent;
}
.danger {
  border: 1px solid #d44051;
  color: #d44051;
  background: transparent;
}
.select {
  border: 1px solid #4385f2;
  color: #fff;
  background: #4385f2;
}
.main-btn {
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 10px 2px 0 10px;
  font-size: 13px;
  font-weight: 400;
  .btn-icon {
    height: 20px;
    vertical-align: top;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}
.main-input {
  width: 208px;
  height: 34px;
  margin: 0 10px;
  text-indent: 10px;
  text-decoration: #378ddd;
  background: #fefefe;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-right: 10px;
}
.main-input:focus {
  outline: 1px solid #378ddd;
}
.height10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.t-item2 {
  width: 100%;
  margin-right: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  .t-item2__header {
    margin: 10px 0px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  }
  .t-item2__title {
    padding: 24px 28px;
    .highlight {
      /deep/ .el-breadcrumb__inner {
        color: #378ddd !important;
      }
    }
    .checkFile {
      cursor: pointer;
      color: #606266;
    }
    .checkFile:hover {
      color: #0c7de6;
    }
  }
  .t-item2__header__info {
    padding: 0 28px 24px 28px;
    display: flex;
  }
  .info-info {
    padding-left: 20px;
  }
  .info-icon {
    height: 100px;
  }
  .create-info {
    padding: 10px 0;
    color: #999999;
  }
  .team-btn-group {
    position: absolute;
    right: 40px;
  }
  .margin10 {
    margin: 0 10px;
  }
  .info-btn {
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 13px;
    font-weight: 400;
    .btn-icon {
      height: 14px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }
}
.t-tabs {
  height: 34px;
  width: 100%;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: flex-start;
  .active {
    border-bottom: 2px solid #0c7de6;
    color: #0c7de6;
  }
  .t-tabs__item {
    margin: 2px 20px 0px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }
  .t-tabs__item:hover {
    cursor: pointer;
  }
}
.t-item2__body {
  background: #fff;
  height: 100vh;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  .t-item {
    padding: 12px 0px;
    width: 100%;
    display: inline-block !important;
  }
  .t-table {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    position: absolute;
    .table-column__membername {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
    }
    /deep/ .el-table__cell {
      padding: 6px !important;
      font-size: 13px;
      font-weight: 400;
      color: #333333;
    }
    /deep/ .el-tag--owner {
      background: #f7edd7;
      color: #d48c00;
    }
    /deep/ .el-tag--manager {
      background: #d9f6ed;
      color: #008259;
    }
    .options__note {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .disabled {
      color: #b2b2b2 !important;
    }
    .table_device_power {
      color: #333;
      cursor: pointer;
    }
    .table_device_power:hover {
      color: #0c7de6;
    }
  }
  /deep/ .table_header {
    font-size: 13px;
    font-weight: bolder !important;
    color: #333333;
    background: #e9eff3;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
  }
  .disabled {
    cursor: not-allowed !important;
  }
}
.cell {
  .el-icon-delete {
    cursor: pointer;
  }
  .disabled {
    color: #dddddd;
    cursor: not-allowed !important;
  }
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--default {
  color: #4385f2;
  border: 1px solid #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
.dialog-body {
  margin: 30px 0px;
  .selectbtype {
    position: relative;
  }
  /deep/ .el-table__cell {
    padding: 6px !important;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  .item_title {
    display: inline-block;
    width: 80px;
  }
  .item_class {
    font-size: 13px;
    font-weight: 400;
    color: #0c7de6;
    width: 100%;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // text-overflow: ellipsis;
    -webkit-line-clamp: 4; /*限制行数为4行*/
    line-clamp: 4; /*限制行数为4行*/
  }
  .must-left {
    /deep/.el-input--suffix .el-input__inner {
      padding-left: 30px;
    }
  }
  /deep/ .el-input__count-inner {
    background: transparent;
  }
}
.dialog-body2 {
  padding: 0;
  background: #eeeeee;
  .btn-group {
    width: 100%;
    background: #ffffff;
    padding: 0 0 8px 0;
  }
  .table-item {
    width: 50%;
    padding: 8px 10px;
    background: #ffffff;
    margin: 8px 0;
  }
  .table-item__input {
    width: 100%;
    /deep/ .el-input__inner {
      background: #ffffff !important;
      height: 30px;
      line-height: 30px;
    }
  }
  .el-table {
    margin-top: 10px;
  }
  /deep/ .el-table__cell {
    padding: 6px !important;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  .clicked {
    background: #4385f2;
    color: #fff;
  }
}
.dialog-body3 {
  padding: 30px 0;
  .item_title {
    display: inline-block;
    width: 80px;
  }
  .item_class {
    font-size: 13px;
    font-weight: 400;
    color: #0c7de6;
    width: 100%;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // text-overflow: ellipsis;
    -webkit-line-clamp: 4; /*限制行数为4行*/
    line-clamp: 4; /*限制行数为4行*/
  }
  .selectbtype {
    position: relative;
  }
  /deep/ .el-input__count-inner {
    background: transparent;
  }
  /deep/ .el-input__count {
    background: transparent;
  }
  /deep/ .el-textarea__inner {
    background: #f4f4f4 !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
}
.urlInfo {
  font-size: 13px;
  padding: 10px;
  display: inline-block;
}
.copyUrlInfo {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
}
.input-disabled {
  ::v-deep {
    .el-input__inner {
      background: #dddddd !important;
    }
  }
}
.selectManyClass {
  display: inline-block;
  ::v-deep {
    .el-input {
      height: 30px !important;
      font-size: 12px;
    }
    .el-input__prefix,
    .el-input__suffix {
      height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border-radius: 0;
    }
    .el-input__suffix {
      top: 2px;
    }
    .el-input__icon {
      line-height: inherit;
    }
    .el-input__suffix-inner {
      display: inline-block;
    }
  }
}
.selectClass {
  display: inline-block;
  ::v-deep {
    .el-input {
      height: 30px !important;
      font-size: 12px;
    }
    .el-input__prefix,
    .el-input__suffix {
      height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border-radius: 0;
    }
    .el-input__suffix {
      top: 7px;
    }
    .el-input__icon {
      line-height: inherit;
    }
    .el-input__suffix-inner {
      display: inline-block;
    }
  }
}
.btn-icon {
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.table_select {
  /deep/ .el-input__inner {
    width: 100px;
    height: 23px;
    line-height: 22px;
    padding: 0 10px !important;
    background: transparent !important;
    color: #333333 !important;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 4px 0px rgba(3, 15, 76, 0.1);
  }
  /deep/ .el-input {
    width: 90%;
  }
  /deep/ .el-input:hover {
    .el-input__inner {
      color: #0c7de6 !important;
    }
    .el-input__icon {
      color: #0c7de6 !important;
    }
  }
  /deep/ .el-input__suffix {
    top: -1px;
    left: 75px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
}
.confirmItem {
  position: absolute;
  text-align: left;
  bottom: 25px;
  left: 40px;
  width: 200px;
}
.confirmFont {
  font-size: 13px;
  color: #333333;
}
.el-pagination {
  padding: 12px 20px 12px 12px;
  display: flex;
  justify-content: right;
}
.el-tag {
  height: 23px !important;
  line-height: 22px !important;
}
.infinite-list-wrapper {
  margin-top: 10px;
  width: 100%;
}
.list-item {
  width: auto;
  border-bottom: 1px solid #eeeeee;
  padding: 5px 0;
  border-collapse: collapse;
  font-size: 13px;
  .list-item__name {
    display: inline-block;
    width: calc(99% - 45px);
    font-weight: 400;
    color: #333333;
    text-indent: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
  }
  .list-item__button {
    width: 45px;
    text-align: right;
    display: inline-block;
    vertical-align: top;
  }
}
.list-item:hover {
  background: #dce6f8;
}
.must::before {
  content: "* ";
  color: #ec3838;
}
.form-label {
  display: flex;
  flex-direction: column;
  .form-labrl__item {
    margin: 5px 0;
  }
}
.addLabel {
  display: inline-block;
  span {
    cursor: pointer;
    color: #4385f2;
    font-size: 20px;
    margin-left: 10px;
  }
}
.delLabel {
  display: inline-block;
  span {
    cursor: pointer;
    color: #d44051;
    font-size: 20px;
    margin-left: 10px;
  }
}
.iconfont {
  vertical-align: text-bottom;
  height: 15px;
  padding-right: 2px;
  color: #0c7de6;
}
.project-main {
  font-size: 13px;
  .main-project:hover {
    background: #dce6f8;
  }
  .main-file:hover {
    background: #dce6f8;
  }
  .main-cell:hover {
    background: #dce6f8;
  }
  .project-main__item {
    width: 100%;
    cursor: pointer;
    .main-project {
      .project-main__item-icon {
        display: inline-block;
        width: 20px;
        padding: 5px 0;
      }
      .project-main__item-name {
        display: inline-block;
        width: auto;
      }
      .project-main__item-select {
        width: 20px;
        float: right;
      }
    }
    .project-main__item-file {
      width: 100%;
      .main-file {
        width: auto;
        padding: 5px 0 5px 10px;
        cursor: pointer;
        display: block;
        .project-main__item-icon {
          display: inline-block;
          width: 20px;
        }
        .project-main__item-name {
          display: inline-block;
          width: auto;
        }
        .project-main__item-select {
          width: 20px;
          float: right;
        }
      }
    }
    .project-main__item-cell {
      width: 100%;
      .main-cell {
        padding: 5px 0 5px 40px;
        cursor: pointer;
        display: block;
        .project-main__item-icon {
          display: inline-block;
          width: 20px;
        }
        .project-main__item-name {
          display: inline-block;
          width: auto;
        }
        .project-main__item-select {
          width: 20px;
          float: right;
        }
      }
    }
    .project-main__item-cellcell {
      width: 100%;
      .main-cell {
        padding: 5px 0 5px 30px;
        cursor: pointer;
        display: block;
        .project-main__item-icon {
          display: inline-block;
          width: 20px;
        }
        .project-main__item-name {
          display: inline-block;
          width: auto;
        }
        .project-main__item-select {
          width: 20px;
          float: right;
        }
      }
    }
  }
}
.dialog-body-search {
  padding: 0px;
  .search-header {
    display: flex;
    padding: 10px;
    .el-input {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      height: 30px;
      line-height: 30px;
      background: transparent !important;
      vertical-align: middle;
      border-radius: 4px !important;
    }
    .el-cascader {
      /deep/ .el-input__suffix {
        top: 0px !important;
      }
    }
    .componentSelect {
      margin-top: 0px !important;
      margin-right: 10px;
    }
  }
  .search-body {
    /deep/ .table_header {
      font-size: 13px;
      font-weight: bold !important;
      color: #333333;
      background: #e9eff3;
      padding: 5px 0;
    }
    /deep/ .el-table__cell {
      font-size: 13px !important;
      padding: 5px 0 !important;
      color: #333333;
    }
  }
}
//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>
